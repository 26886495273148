import DocumentEntity from "../component/DocumentsPerson/DocumentEntity";
import DocumentIndividual from "../component/DocumentsPerson/DocumentIndividual";
import DocumentSelf from "../component/DocumentsPerson/DocumentSelf";
import PreviewFiles from "../component/Preview/PreviewFiles";
import DocumentOther from "../component/DocumentsPerson/DocumentOther";
import DocumentNonResident from "./DocumentsPerson/DocumentNonResident";
import { IFiles } from "../../../../../types";

interface IDocumentsProps {
    files: IFiles;
    showLegalStatus: number;
    showDocComment: string;
    showResidentStatus: boolean
}

const Documents: React.FC<IDocumentsProps> = ({
    files,
    showLegalStatus,
    showDocComment,
    showResidentStatus
}) => {

    return (
        <div className="fix">
            {showDocComment && <div className="input-valid valid-blue" style={{margin: "0px"}}>
                <div className="input-comment-only">
                    <span className="input-comment-only-title">Комментарий ЕМГ: <span className="input-comment-text">{showDocComment}</span></span>
                </div>
            </div>}
            {files && <PreviewFiles files={files}/>}

            {showLegalStatus === 1 && showResidentStatus && <DocumentEntity files={files}/>}
            {showLegalStatus === 2 && showResidentStatus && <DocumentIndividual files={files}/>}
            {showLegalStatus === 3 && showResidentStatus && <DocumentSelf files={files}/>}
            {!showResidentStatus && <DocumentNonResident files={files}/>}
            <div className="frame-line"></div>
            <DocumentOther files={files}/>
        </div>
    );
};

export default Documents;
