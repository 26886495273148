import { useDispatch } from "react-redux";
import { DadataActionTypes } from "../../../../../../store/types/dadata";
import "../../../../../../style/input.css";
import InputField from "../../../../../tools/Input/InputField";
import TextAreaField from "../../../../../tools/Input/TextAreaField";
import { Button } from "../../../../../../Shared";
import { FieldDefinitionType, IFullName, IInn } from "../../../../../../types";

interface IIndividualProps {
    inn: IInn;
    address: FieldDefinitionType;
    address2: FieldDefinitionType;
    edo: FieldDefinitionType;
    loading: boolean;
    passport: FieldDefinitionType;
    ogrn: FieldDefinitionType;
    phone: FieldDefinitionType;
    email: FieldDefinitionType;
    fullName: IFullName;
    showLegalStatus: number;
    useTrek: any;
    showResidentStatus: boolean;
}

const Individual: React.FC<IIndividualProps> = ({
    inn,
    address,
    address2,
    edo,
    loading,
    passport,
    ogrn,
    phone,
    email,
    fullName,
    showLegalStatus,
    useTrek,
    showResidentStatus
}) => {
    const dispatch = useDispatch();

    const onClickINN = () => {
        let vInn = inn.value;
        dispatch({ type: DadataActionTypes.FETCH_DADATA, vInn });
    };

    const addressValue = showLegalStatus === 2 ? address : address2;
    const addressLabel = showLegalStatus === 2 ? "Юридический адрес":"Почтовый адрес (для корреспонденции)"

    return (
        <div className="input-group">
            <InputField name={"inn"}
                useValue={inn}
                label={"ИНН"}
                placeholder={showResidentStatus === true ? "Введите 12-ти значный ИНН" : "Введите ИНН"}
                useTrek={useTrek}
                disabled={inn.isDisabled}
            />

            {showLegalStatus !== 3 && <div className="button-input"><Button
                text={(loading ? "Заполнение реквизитов..." : "Заполнить реквизиты автоматически по ИНН")}
                onClick={() => { onClickINN() }}
                disabled={(loading || inn.isIndividual || inn.isResident || inn.isEmpty || inn.isNotLengthINN) ? true : false}
            /></div>}

            <div className={fullName.value.showComment ? "input-group-name valid-blue" : "input-group-name"}>
                <InputField name={"firstname"}
                    useValue={fullName.surname}
                    label={"Фамилия"}
                    placeholder='Фамилия'
                />
                <InputField name={"surname"}
                    useValue={fullName.firstname}
                    label={"Имя"}
                    placeholder='Имя'
                />
                <InputField name={"patronymic"}
                    useValue={fullName.patronymic}
                    label={"Отчество"}
                    placeholder='Отчество (при наличии)'
                />
                {fullName.value.showComment && <div className="input-valid"><div className="input-comment">Комментарий ЕМГ: <span className="input-comment-text">{fullName.value.showComment}</span></div></div>}
            </div>

            {showLegalStatus !== 3 && <InputField name={"ogrn"}
                useValue={ogrn}
                label={"ОГРН"}
                placeholder='Например, 1254500032'
            />}

            <InputField name={"passport"}
                useValue={passport}
                label={"Данные паспорта"}
                placeholder='Например, 7719 456456, выдан ГУ МВД по г. Москве, 12.01.2004 г.'
            />

            <InputField name={"phone"}
                useValue={phone}
                label={"Телефон"}
                placeholder=''
            />

            <InputField name={"IndividualEmail"}
                useValue={email}
                label={"Электронная почта"}
                placeholder='name@example.com'
            />

            <TextAreaField name={"addressValue"}
                useValue={addressValue}
                label={addressLabel}
                placeholder='Например, 110350, г. Москва, ул. Кожевникова 25, кв. 5'
            />

            <InputField name={"edo"}
                useValue={edo}
                label={"Идентификатор участника ЭДО"}
                placeholder='Например, 99912222020203443-КЕУ'
            />

        </div >
    );
};

export default Individual;
