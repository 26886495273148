import { useEffect, useState } from 'react';
import { IValidations } from '../../../../types/IValidation';

export function useValidation(value: string, validations?: IValidations, LegalStatus?: number, ResidentStatus?: boolean, showFileInfo: any = null) {
    const [isEmpty, setEmpty] = useState(false);
    const [isRequired, setRequired] = useState(false);
    const [minLengthError, setMinLengthError] = useState(false);
    const [maxLengthError, setMaxLengthError] = useState(false);
    const [error, setError] = useState('');
    const [inputValid, setInputValid] = useState(false);
    const [disableValid, setDisableValid] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [isResident, setResident] = useState(false);
    const [isEntity, setEntity] = useState(false);
    const [isIndividual, setIndividual] = useState(false);
    const [isNotLengthINN, setNotLengthINN] = useState(false);

    const [typeFileError, setTypeFileError] = useState(false);

    const [colorError, setColorError] = useState('');
    const [fileError, setFileError] = useState("primary");

    const onValid = (e: boolean) => {
        setDisableValid(e)
        if (e === true) {
            setInputValid(e)
            setRequired(false)
        }
        else {
            setRequired(true)
        }
    }

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'isEmpty':
                    if (value) {
                        setEmpty(false)
                    }
                    else {
                        setEmpty(true)
                        setError('Поле не может быть пустым')
                        setColorError('red')
                        setRequired(true)
                        // setStyleValid('_input-valid valid-red')
                    }
                    break;
                // case 'minLength':
                //     if (value.length < validations[validation]) {
                //         if (value) {
                //             setError('Некорректная длина')
                //             setMinLengthError(true)
                //             setColorError('red')
                //             // setStyleValid('_input-valid valid-red')
                //             // setBorderError('form-control is-invalid')
                //         }
                //     }
                //     else
                //         setMinLengthError(false)
                //     break;
                // case 'maxLength':
                //     if (value.length > validations[validation]) {
                //         if (value) {
                //             setError('Некорректная длина')
                //             setMaxLengthError(true)
                //             setColorError('red')
                //             // setStyleValid('_input-valid valid-red')
                //             // setBorderError('form-control is-invalid')
                //         }
                //     }
                //     else
                //         setMaxLengthError(false)
                //     break;
                case 'isPhone':
                    const phoneNumberPattern = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
                    if (phoneNumberPattern.test(value) || value === '') {
                        setPhoneError(false)
                    }
                    else {
                        setError('Некорректный телефон')
                        setPhoneError(true)
                        setColorError('orange')
                        // setStyleValid('_input-valid valid-orange')
                        // setBorderError('form-control border-warning')

                    }
                    break;
                case 'isEmail':
                    const emailNumberPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
                    if (emailNumberPattern.test(value) || value === '') {
                        setEmailError(false)
                    }
                    else {
                        setError('Некорректный Email')
                        setEmailError(true)
                        setColorError('orange')
                        // setStyleValid('_input-valid valid-orange')
                        // setBorderError('form-control border-warning')
                    }

                    break;
                case 'isDocEmpty':
                    if (value) {
                        setEmpty(false)

                    }
                    else {
                        setEmpty(true)
                        setError('Отсутствует путь к файлу')
                        setColorError('red')
                        // setStyleValid('_input-valid valid-red')
                        setFileError('danger')
                        setRequired(true)
                    }
                    break;
                case 'isTypeFile':
                    if (showFileInfo?.type === "image/jpeg" || showFileInfo?.type === "application/pdf" ||
                        showFileInfo?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        showFileInfo?.type === 'application/msword' ||
                        showFileInfo?.type === 'image/img' ||
                        showFileInfo?.type === 'image/png' || showFileInfo?.type === "image/jpg" ||
                        showFileInfo?.type === 'application/vnd.ms-excel' ||
                        showFileInfo?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        showFileInfo?.type === 'application/x-rar-compressed' ||
                        showFileInfo?.type === 'application/zip' ||
                        showFileInfo === ''
                    ) {
                        setTypeFileError(false)
                        // setFileError("success")
                    }
                    else {
                        setError('Неправильный формат файла')
                        setTypeFileError(true)
                        setColorError('red')
                        // setStyleValid('_input-valid valid-red')
                        setFileError('danger')
                    }
                    break;
                case 'isEntity':
                    if (value && ResidentStatus === true && LegalStatus === 1 && value.length === 12) {
                        setError('Вы ввели ИНН физического лица или ИП')
                        setEntity(true)
                        setColorError('red')
                    }
                    else {
                        setEntity(false)

                    }
                    break;
                case 'isIndividual':
                    if (value && ResidentStatus === true && LegalStatus === 2 && value.length === 10) {
                        setError('Вы ввели ИНН юридического лица')
                        setIndividual(true)
                        setColorError('red')
                    }
                    else {
                        setIndividual(false)

                    }
                    break;
                // case 'isResident':
                //     // const innNumberPattern = /^[0-9]+$/; 
                //     const innNumberPattern = /^\d+$/;
                //     if (innNumberPattern.test(value) || (value === '') || ResidentStatus === 2) {
                //         setResident(false)
                //     }
                //     else {
                //         setError('Введенный ИНН является нерезидента')
                //         setResident(true)
                //         setColorError('red')
                //     }
                //     break;
                case 'isResident':
                    // const innNumberPattern = /^[0-9]+$/; 
                    const innNumberPattern = /^\d+$/;
                    if (!innNumberPattern.test(value) && ResidentStatus === true && value !== "") {
                        setError('Вы ввели ИНН нерезидента')
                        setResident(true)
                        setColorError('red')
                    }
                    else {
                        setResident(false)
                    }
                    break;
                case 'isNotLengthINN':
                    const innNumberPattern2 = /^\d+$/;
                    if ((value?.length === 10 || value?.length === 12) || (ResidentStatus !== true) || !innNumberPattern2.test(value)) {
                        setNotLengthINN(false)
                    }
                    else {
                        setNotLengthINN(true)
                        setError('Неправильный ИНН')
                        setColorError('red')
                    }
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if (isEmpty || maxLengthError || minLengthError || phoneError || emailError || typeFileError || isResident || isEntity || isIndividual || isNotLengthINN) {
            setInputValid(false)
        }
        else
            setInputValid(true)
    }, [isEmpty, maxLengthError, minLengthError, phoneError, emailError, typeFileError, isResident, isEntity, isIndividual, isNotLengthINN])

    return {
        isEmpty,
        minLengthError,
        error,
        inputValid,
        disableValid,
        phoneError,
        emailError,
        onValid,
        isResident,
        setResident,
        colorError,
        typeFileError,
        fileError,
        isRequired,
        isEntity,
        isIndividual,
        isNotLengthINN,
        setNotLengthINN
        // styleValid, 
        // setStyleValid
    }
}