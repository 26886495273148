import { IContact, IModal } from "../../../../types";
import useSVG from "../../../Data/useSVG";
import Frame from "../../../tools/Frame/Frame";
import Contacts from "./component/Contacts";

interface FormLineType {
  showContactsComment: string;
  contacts: IContact[];
  setContacts: (value: IContact[]) => void;
  setModal: (value: IModal) => void;
  headContactFullname?: string;
  setHeadContactFullname: (value: string) => void;
  modal: IModal;
}

const ContactsForm: React.FC<FormLineType> = ({
  showContactsComment,
  contacts,
  setContacts,
  setModal,
  modal,
  headContactFullname,
  setHeadContactFullname
}) => {

  const { iconContact } = useSVG();

  return (
    <Frame
      icon={iconContact}

      header={"Контакты"}

      body={
        <Contacts
          showContactsComment={showContactsComment}
          contacts={contacts} setContacts={setContacts}
          setModal={setModal} modal={modal}
          headContactFullname={headContactFullname}
          setHeadContactFullname={setHeadContactFullname}
        />
      }
    />
  );
};

export default ContactsForm;
