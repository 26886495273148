import { IBankProps } from "../../../../../../types";
import useSVG from "../../../../../Data/useSVG";

interface IPreBankPropsProps {
    checkingАccount: IBankProps;
    handleDeleteClick: (id: number) => void;
    handleEditClick: (checkingАccountID: number) => void;
    index: number;
}

const PreBankProps: React.FC<IPreBankPropsProps> = ({
    checkingАccount,
    handleDeleteClick,
    handleEditClick,
    index
}) => {

    const { iconBankPrew } = useSVG();

    const { bankName, bankCity, bik, accountNumber, correspondentAccount, comment, id } = checkingАccount;

    return (
        <div className="prew">
            <div className="prew-header">{iconBankPrew}
                <div className="prew-header-text">
                    {bankName}
                    <div className="prew-info">
                        {bankCity && <div> {bankCity}</div>}
                        {bik && <div>БИК {bik}</div>}
                        {accountNumber && <div>Р/С {accountNumber}</div>}
                        {correspondentAccount && <div>К/С {correspondentAccount}</div>}
                        {comment}<div />
                    </div>
                </div>
            </div>

            <div className="prew-group-btn">
                <div className="prew-button" onClick={() => handleEditClick(index)}>
                    Изменить счет
                </div>
                <div className={`prew-button ${id === 0 ? "" : "disabled"}`}
                    onClick={id === 0 ? () => handleDeleteClick(index) : undefined}
                >
                    Удалить счет
                </div>
            </div>
            <div className="prew-line" />
            {/* {(!props.handleDeleteClick && props.bankPropsLength !== props.index) && <div className="result-line" />} */}
        </div>
    );
};

export default PreBankProps;
