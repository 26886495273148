import { DadataAction, DadataState, DadataActionTypes } from "../types/dadata"

const initialState: DadataState = {
    loading: false,
    error: null,
    dadata: {}

}

export const dadataReducer = (state = initialState, action: DadataAction): DadataState => {
    switch (action.type) {
        case DadataActionTypes.FETCH_DADATA:
            return { loading: true, error: null, dadata: [] }
        case DadataActionTypes.FETCH_DADATA_SUCCESS:
            return {
                loading: false, error: null, dadata: action.payload
            }
        case DadataActionTypes.FETCH_DADATA_ERROR:
            return { loading: false, error: action.payload, dadata: [] }
        default:
            return state
    }
}