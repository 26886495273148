import { createAxiosRequest } from "../api";

export const API_getNewCo = async (newCoHash: string) => {
    return createAxiosRequest({
        url: `Contragent/${newCoHash}`,
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    });
};