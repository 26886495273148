import CheckBoxSquare from "../../../../tools/CheckBox/CheckBoxSquare";
import '../../../../../style/checkbox.css';
import { Button } from "../../../../../Shared";

const PartnerType = (props: any) => {
  return (
    <div className="button-group">
      <div className="checkbox-group">
        <CheckBoxSquare setCheck={props.setProviderStatus} useCheck={props.useProvider} text={"Поставщик"} id={"cb_useProvider"} />
        <CheckBoxSquare setCheck={props.setClientStatus} useCheck={props.useClient} text={"Клиент"} id={"cb_useClient"} />
      </div>

      <Button text={"Далее"} onClick={() => { props.setFormStatus(3) }} disabled={false} />
    </div>
  );
};

export default PartnerType;
