import { Button } from "../../../../../Shared";
import { IContact, IModal } from "../../../../../types";
import PreviewContacts from "../Preview/PreviewContacts";

interface IContactsProps {
    showContactsComment: string;
    contacts: IContact[];
    setContacts: (value: IContact[]) => void;
    setModal: (value: IModal) => void;
    modal: IModal;
    headContactFullname?: string;
    setHeadContactFullname: (value: string) => void;
}

const Contacts: React.FC<IContactsProps> = ({
    showContactsComment,
    contacts,
    setContacts,
    setModal,
    headContactFullname,
    setHeadContactFullname,
    modal
}) => {
    return (
        <div className="fix">
            {showContactsComment && <div className="input-valid valid-blue" style={{ margin: "0px" }}>
                <div className="input-comment-only">
                    <span className="input-comment-only-title">Комментарий ЕМГ: <span className="input-comment-text">{showContactsComment}</span></span>
                </div>
            </div>}

            <PreviewContacts
                contacts={contacts}
                setContacts={setContacts}
                setModal={setModal}
                modal={modal}
                headContactFullname={headContactFullname}
                setHeadContactFullname={setHeadContactFullname}
            />

            <div className="button-group">
                <Button text={"Добавить контакт"} onClick={() => setModal({ ...modal, modalContactsResume: true })} disabled={false} />
            </div>
        </div>
    );
};

export default Contacts;
