import useFormContragent from './components/Data/useFormContragent';
import Main from './components/Main/Main';
import { Footer } from './features/Footer';
import { Header } from './features/Header';
import "./style/main.css";

const App = () => {
    const { showForm, setFormStatus, showValidForm, setValidFormStatus } = useFormContragent();

    return (
        <div className="App">
            <Header
                showForm={showForm}
                setFormStatus={setFormStatus}
                showValidForm={showValidForm}
            />
            <Main
                showForm={showForm}
                setFormStatus={setFormStatus}
                setValidFormStatus={setValidFormStatus}
            />
            <Footer
                companyName='Европейская медиагруппа'
                startYear={2022}
                websiteUrl='emg.fm'
                supportMail='newco@emg.fm'
                supportText='Обращение в техподдержку NewCo'
            />
        </div>
    );
}

export default App;