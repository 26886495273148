import { useEffect, useState } from "react";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { IBankProps, IContact, IFields, IFiles } from "../../types";

const useGetContragent = (
    files: IFiles,
    fields: IFields,
    setResidentStatus: (residentStatus: boolean) => void,
    setClientStatus: (clientStatus: boolean) => void,
    setProviderStatus: (providerStatus: boolean) => void,
    setLegalStatus: (legalStatus: number) => void,
    setContacts: (contacts: IContact[]) => void,
    setBankProps: (contacts: IBankProps[]) => void,
    setHeadContactFullname: (value: string) => void
) => {

    const { contragent } = useTypedSelector((state) => state.contragentRequest);

    const [showDocComment, setDocComment] = useState("");
    const [showBankPropsComment, setBankPropsComment] = useState("");
    const [showContactsComment, setContactsComment] = useState("");

    useEffect(() => {
        if (contragent?.legalStatus > 0) {
            let coData = contragent;
            setLegalStatus(coData?.legalStatus);
            setResidentStatus(coData?.isResident);
            setClientStatus(coData?.isClient);
            setProviderStatus(coData?.isProvider);
            if (coData?.legalStatus === 1) {
                fields.company.companyName.set(coData?.fullName);
                fields.company.companyShortName.set(coData?.shortName);
                fields.company.kpp.set(coData?.kpp);
            }

            if (coData?.legalStatus === 2 || coData?.legalStatus === 3) {
                fields.phone.set(coData?.phoneNumber);
                fields.email.set(coData?.email);
                fields.passport.set(coData?.passportNumber);
                let fullName = coData.fullName
                if (fullName.startsWith("ИП "))
                    fullName = fullName.substring(3)
                let names = fullName.split(' ');
                fields.fullName.firstname.set(names[1]);
                fields.fullName.surname.set(names[0]);
                fields.fullName.patronymic.set(names[2]);
            }

            fields.inn.set(coData?.inn);
            if (coData.inn) fields.inn.setDisabled(true)
            fields.company.ogrn.set(coData?.ogrn);
            fields.company.address.set(coData?.legalAddress);
            fields.company.address2.set(coData?.actualAddress);
            fields.edo.set(coData?.edx);
            setHeadContactFullname(coData?.headContact);

            if (coData?.contacts[0] !== null && (coData?.legalStatus !== 3)) {
                const newContacts: IContact[] = coData?.contacts?.map((contact: IContact, key: any) => (
                    {
                        id: contact.id,
                        position: contact.position,
                        fullName: contact.fullName,
                        phoneNumber: contact.phoneNumber,
                        email: contact.email,
                        comment: contact.comment
                    }
                ))

                if (!coData?.contacts?.some((contact: IContact) => contact.fullName === coData?.headContact)) {
                    newContacts.push({
                        id: 0,
                        position: "Руководитель",
                        fullName: coData?.headContact || "",
                        phoneNumber: "",
                        email: "",
                        comment: ""
                    });
                }


                setContacts(newContacts);
            }

            if ((coData?.bankDetails[0] !== null)) {
                const newBankProps: IBankProps[] = coData?.bankDetails?.map((bankProps: IBankProps, key: any) => (
                    {
                        id: bankProps.id,
                        accountNumber: bankProps.accountNumber,
                        bankCity: bankProps.bankCity,
                        bankName: bankProps.bankName,
                        bik: bankProps.bik,
                        correspondent_account: bankProps.correspondentAccount,
                        comment: bankProps.comment
                    }
                ))
                setBankProps(newBankProps);
            }

            //documents
            let coDoc = contragent?.documents;

            if (coDoc) {
                Object.keys(files).forEach(key => {
                    const prop = `doc${key.charAt(0).toUpperCase()}${key.slice(1)}`;
                    if (prop in coDoc) {
                        files[key].set(coDoc[prop]);
                    }
                });
            }

            // //comments
            // let coCom = contragent?.comments;
            // if (coData?.legalStatus === 1) {
            //     fields.company.companyName.setComment(coCom?.com_full_name);
            //     fields.company.companyShortName.setComment(coCom?.com_short_name);
            //     fields.company.address2.setComment(coCom?.com_legal_address);
            //     fields.company.kpp.setComment(coCom?.com_kpp);
            // }

            // if (coData?.legal_status === 2 || coData?.legal_status === 3) {
            //     fields.phone.setComment(coCom?.com_phone_number);
            //     fields.email.setComment(coCom?.com_email);
            //     fields.fullName.value.setComment(coCom?.com_full_name);
            // }

            // fields.inn.setComment(coCom?.com_inn);
            // fields.company.ogrn.setComment(coCom?.com_ogrn);
            // fields.company.address.setComment(coCom?.com_actual_adress);
            // fields.edo.setComment(coCom?.com_edx);

            // setDocComment(coCom?.com_files);
            // setBankPropsComment(coCom?.com_bank_props);
            // setContactsComment(coCom?.com_contacts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contragent]);

    return {
        showOtherComment: {
            showDocComment, showBankPropsComment,
            showContactsComment
        }
    }
}

export default useGetContragent;