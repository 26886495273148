import { IBankProps, IModal } from "../../../../types";
import useSVG from "../../../Data/useSVG";
import Frame from "../../../tools/Frame/Frame";
import BankProps from "./component/BankProps";

interface IBankPropsFormProps {
  showBankPropsComment: string;
  bankProps: IBankProps[];
  setBankProps:  (bankProps: IBankProps[]) => void;
  setModal: (value: IModal) => void;
  modal: any;
}

const BankPropsForm: React.FC<IBankPropsFormProps> = ({
  showBankPropsComment,
  bankProps,
  setBankProps,
  setModal,
  modal
}) => {
  
  const { iconBank } = useSVG();

  return (
    <Frame
      icon={iconBank}

      header={"Расчетный счет"}

      body={
        <BankProps
          showBankPropsComment={showBankPropsComment}
          bankProps={bankProps} setBankProps={setBankProps}
            setModal={setModal} modal={modal} 
        />
      }
    />
  );
};

export default BankPropsForm;
