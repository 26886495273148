import { IContragentDataModel } from "../../models";
import { IContact, IFields, IFiles } from "../../types";
import { IBankProps } from "../../types/IBankProps";

const useToJSONContragent = (
  files: IFiles, fields: IFields,
  showLegalStatus: number, showResidentStatus: boolean, useClient: boolean, useProvider: boolean,
  contacts: IContact[], bankProps: IBankProps[], headContactFullname?: string) => {

  let fullName = fields.fullName.surname.value + " " + fields.fullName.firstname.value + " " + fields.fullName.patronymic.value;

  if (showLegalStatus === 2) {
    fullName = "ИП " + fullName;
  }

  const ContragentJson: IContragentDataModel = {
    inn: fields.inn.value,
    fullName: showLegalStatus === 1 ? fields.company.companyName.value : fullName,
    passportNumber: fields.passport.value,
    phoneNumber: fields.phone.value,
    email: fields.email.value,
    edx: null,
    kpp: fields.company.kpp.value,
    ogrn: null,
    headContact: headContactFullname !== undefined ? headContactFullname : null,
    shortName: showLegalStatus === 1 ? fields.company.companyShortName.value : fullName,
    legalAddress: fields.company.address.value,
    actualAddress: fields.company.address2.value,
    legalStatus: showLegalStatus,
    isResident: showResidentStatus,
    isClient: useClient,
    isProvider: useProvider,
    bankDetails: bankProps,
    contacts: contacts,
    documents: {}
  };

  return ContragentJson;

}

export default useToJSONContragent;