import { IContact, IModal } from "../../../../../types";
import PreContact from "./component/PreContact";

interface IPreviewContactsProps {
    contacts: IContact[];
    setContacts: (value: IContact[]) => void;
    setModal: (value: IModal) => void;
    modal: IModal;
    headContactFullname?: string;
    setHeadContactFullname: (value: string) => void;
}

const PreviewContacts: React.FC<IPreviewContactsProps> = ({
    contacts, setContacts,
    setModal, modal,
    headContactFullname,
    setHeadContactFullname
}) => {

    const handleDeleteClick = (index: number) => {
        const newContacts = contacts.filter((_, i) => i !== index);
        setContacts(newContacts);
        if (headContactFullname === contacts[index].fullName) {
            console.log(headContactFullname === contacts[index].fullName)
            setHeadContactFullname("");
        }
    }

    const handleEditClick = (index: number) => {
        setModal({ ...modal, modalContactsResume: true, modalEdit: true, modalID: index })
    }

    return (
        <div className="prew-group">

            {contacts.map((contact: IContact, index: number) => (
                contact.fullName &&
                <PreContact
                    key={index}
                    index={index}
                    contact={contact}
                    handleDeleteClick={handleDeleteClick}
                    handleEditClick={handleEditClick}
                    isHeadContact={contact.fullName === headContactFullname}
                />
            ))}

        </div>
    );
};

export default PreviewContacts;
