import React, { useState } from "react";
import "./fileUpload.css";
import { IFileInputState } from "../../../types";
import { Button } from "../../../Shared";

interface IFileUploadProps {
    useFile: IFileInputState,
    text: string
    disabled?: boolean;
}

const FileUpload: React.FC<IFileUploadProps> = ({ useFile, text, disabled }) => {

    const [drag, setDrag] = useState(false);

    const upload = (e: any) => {
        document.getElementById(e)?.click();
    }

    const TXT_BUTTON = <div>{text}{useFile.isRequired && <span style={{ color: 'red' }}>*</span>}</div>

    const handleDragEvent = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!disabled) {
            setDrag(e.type === 'dragover');
            e.dataTransfer.dropEffect = 'move';
            if (e.type === 'drop') {
                setDrag(false);
                const file = e.dataTransfer.files[0];
                useFile.uploadFile(file);
            }
        }
    };

    return (
        <div
            className={drag && !disabled ? "drop-area" : ""}
            onDragOver={handleDragEvent}
            onDragLeave={handleDragEvent}
            onDrop={handleDragEvent}
        >
            <input id={useFile.id} {...useFile.bind} hidden disabled={disabled} />

            <Button
                onClick={() => upload(useFile.id)}
                disabled={disabled}
                text={TXT_BUTTON}
                large
            />

        </div>
    );
};

export default FileUpload;