import useSVG from "../../../../../../Data/useSVG";

interface FormLineType {
    showLegalStatus: any;
    fields: any;
}

const ResultDetails: React.FC<FormLineType> = ({
    showLegalStatus,
    fields
}) => {


    const { iconDetailsPrew } = useSVG();

    return (
        <div className="prew prew-group">
            <div className="prew-header">{iconDetailsPrew}
                <div className="prew-header-group">
                    <div className="prew-header-text">
                        {showLegalStatus === 1 && (fields.company.companyShortName.value)}
                        {(showLegalStatus === 2 || showLegalStatus === 3) && <span>{fields.fullName.surname.value}  {fields.fullName.firstname.value}  {fields.fullName.patronymic.value}</span>}
                        <div className="prew-info">
                            {fields.inn?.value && <div>ИНН {fields.inn?.value} </div>}
                            {fields.company.kpp?.value && <div>КПП {fields.company.kpp?.value} </div>}
                            {fields.company.ogrn?.value && <div>ОГРН {fields.company.ogrn?.value} </div>}
                            {fields.phone?.value && <div>Телефон {fields.phone?.value} </div>}
                            {fields.email?.value && <div>E-mail {fields.email?.value} </div>}
                        </div>
                    </div>

                    {fields.company?.address.value && <div className="prew-header-text">
                        {showLegalStatus < 3 && <div> Юридический адрес: </div>}
                        {showLegalStatus === 3 && <div> Почтовый адрес: </div>}
                        <div className="prew-info">
                            {fields.company?.address.value}
                        </div>
                    </div>}

                    {fields.company?.address2.value && <div className="prew-header-text">
                        <div> Фактический адрес: </div>
                        <div className="prew-info">
                            {fields.company?.address2.value}
                        </div>
                    </div>}

                    {fields.passport.value && <div className="prew-header-text">
                        <div> Данные паспорта: </div>
                        <div className="prew-info">
                            {fields.passport.value}
                        </div>
                    </div>}

                    {fields?.edo.value && <div className="prew-header-text">
                        {fields.edo.value && <div> Идентификатор ЭДО: </div>}
                        <div className="prew-info">
                            {fields?.edo.value}
                        </div>
                    </div>}
                </div>

            </div>
        </div>
    );
};

export default ResultDetails;
