import './button.css';
import { FC } from 'react';

interface IButtonProps {
  text: string | JSX.Element,
  disabled?: boolean,
  onClick: () => void;
  large?: boolean;
}

const Button: FC<IButtonProps> = ({
  text,
  disabled = false,
  onClick,
  large = false
}) => {
  const buttonTextClass = disabled ? "button-text-disabled" : "button-text";
  const buttonClass = large ? "button large-button" : "button";

  return (
    <button className={buttonClass} onClick={onClick} disabled={disabled}>
      <div className={buttonTextClass}>
        {text}
      </div>
    </button>
  );
};

export default Button;
