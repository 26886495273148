import { useState } from "react";

const useFormContragent = () => {

    const [showForm, setFormStatus] = useState(-1);
    const [showValidForm, setValidFormStatus] = useState(false);
  
    return {
      showForm, setFormStatus,
      showValidForm, setValidFormStatus
    }
  }

  

export default useFormContragent;