import { useEffect, useState } from "react";
import "../../../style/input.css";
import CheckBoxSquareSmall from "../CheckBox/CheckBoxSquareSmall";

interface FormLineType {
    useValue: any,
    label?: string,
    name?: string,
    placeholder?: string,

    useValue2?: any,
    textCheckBox?: string,
    useCheckBox?: boolean,
    setCheckBoxStatus?: any
    loading?: boolean
}

const TextAreaField: React.FC<FormLineType> = ({ useValue, label, name, placeholder, useValue2, textCheckBox, useCheckBox = false, setCheckBoxStatus, loading }) => {

    const [showScrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
        if (useCheckBox === true) { useValue.set(useValue2.value) }
    }, [useCheckBox])
    

    let textarea: any = document.querySelector('#' + name);
    textarea?.addEventListener(('keyup' || "mousedown" || "._input"), resize)

    if (useValue?.value?.length < showScrollHeight) {
        textarea.style.height = "48px";
    }

    function resize(this: any) {
        if (this.scrollTop > 0) {
            this.style.height = this.scrollHeight + "px";
            setScrollHeight(this.scrollHeight)
        }

        if (this.scrollHeight > 48 && this.scrollHeight < showScrollHeight) {
            this.style.height = this.scrollHeight + "px";
        }
    };

    return (
        <div className={useValue.isValid}>
            <div className="input-frame">
                <div className="input-title">
                    <label className="input-title-text" htmlFor={name}>
                        {label}
                        {!useValue.disableValid && useValue.isRequired && <label htmlFor={name} className={"input-title-star"}> *</label>}
                        {textCheckBox && <CheckBoxSquareSmall setCheck={setCheckBoxStatus} useCheck={useCheckBox} text={textCheckBox} id={"cb_address"} actionCheck={useValue.clear()} />}
                    </label>
                    {((!useValue.inputValid && useValue.isDirty)) && <span className={"input-valid-text"} style={{ color: useValue.colorError }}>{useValue.error}</span>}
                </div>

                <textarea name={name}
                    id={name}
                    className="input"
                    type="text"
                    placeholder={placeholder}
                    {...useValue.bind}
                    rows={2}
                    disabled={useCheckBox ? true : false}
                />

                {useValue.showComment && <div className="input-comment">Комментарий ЕМГ: <span className="input-comment-text">{useValue.showComment}</span></div>}

            </div>
        </div>
    );
};

export default TextAreaField;
