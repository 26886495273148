import { call, put, takeEvery } from "redux-saga/effects";
import { ContragentActionTypes } from "../types/contragentTypes";
import { API_getNewCo } from '../../API/fields/get';

export function* sagaGetWatcher() {
    yield takeEvery(ContragentActionTypes.FETCH_CONTRAGENT, sagaWorker);
}

function* sagaWorker(fetchCo: any): any {
    try {
        const data = yield call(API_getNewCo, fetchCo.newCoHash);
        yield put({
            type: ContragentActionTypes.FETCH_CONTRAGENT_SUCCESS,
            payload: data
        })

    } catch (e) {
        yield put({
            type: ContragentActionTypes.FETCH_CONTRAGENT_ERROR,
            payload: "Произошла ошибка при заполнении данных"
        })
    }
}