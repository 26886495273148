import { useState } from "react";
import { IContact, IModal } from "../../types";
import { IBankProps } from "../../types/IBankProps";



const useModalContragent = () => {

  const [contacts, setContacts] = useState<IContact[]>([]);
  const [headContactFullname, setHeadContactFullname] = useState<string | undefined>();
  const [bankProps, setBankProps] = useState<IBankProps[]>([]);
  const [modal, setModal] = useState<IModal>(
    {
      modalContactsResume: false, modalContactsSend: false,
      modalBankPropsResume: false, modalBankPropsSend: false,
      modalValid: true, modalEdit: false, modalID: 0
    });

  return {
    modal, setModal,
    contacts, setContacts,
    bankProps, setBankProps,
    headContactFullname, setHeadContactFullname
  }
}



export default useModalContragent;