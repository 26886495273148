import useSVG from "../../Data/useSVG";

interface FormLineType {
  setCheck: any,
  useCheck: any,
  text?: string,
  id?: string
}

const CheckBoxSquare: React.FC<FormLineType> = ({
  setCheck,
  useCheck,
  text,
  id
}) => {

  const { iconCheckOff, iconCheckOn } = useSVG();

  return (
    <div className='checkbox-unit'>
      <div className="checkbox-square" onClick={() => setCheck(!useCheck)} id={id}>
        {!useCheck && iconCheckOff}
        {useCheck && iconCheckOn}
      </div>
      <label className='checkbox-text' htmlFor={id} onClick={() => setCheck(!useCheck)}>{text}</label>
    </div>
  );
};

export default CheckBoxSquare;
