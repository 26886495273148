import Lottie from "react-lottie-player";
import loadingAnim from './animations/loading.json'
import "./loader.css";

const Loader = () =>  (
        <div className="loader">
            <Lottie loop play
                animationData={loadingAnim}
                style={{ height: '300px', width: '300px' }}
            />
        </div>
    );

export default Loader;
