import { DadataBankState, DadataBankAction, DadataBankActionTypes } from "../types/dadataBank";

const initialState: DadataBankState = {
    loading: false,
    error: null,
    dadataBank: [],
}

export const dadataBankReducer = (state = initialState, action: DadataBankAction): DadataBankState => {
    switch (action.type) {
        case DadataBankActionTypes.FETCH_DADATA_BANK:
            return { loading: true, error: null, dadataBank: [] }
        case DadataBankActionTypes.FETCH_DADATA_BANK_SUCCESS:
            return {
                loading: false, error: null, dadataBank: action.payload
            }
        case DadataBankActionTypes.FETCH_DADATA_BANK_ERROR:
            return { loading: false, error: action.payload, dadataBank: [] }

        default:
            return state
    }

}