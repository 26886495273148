import { Button } from "../../../../../Shared";
import { IBankProps, IModal } from "../../../../../types";
import PreviewBankProps from "../Preview/PreviewBankProps";

interface IBankPropsProps {
    showBankPropsComment: string;
    bankProps: IBankProps[];
    setBankProps: (bankProps: IBankProps[]) => void;
    setModal: (value: IModal) => void;
    modal: IModal;
}

const BankProps: React.FC<IBankPropsProps> = ({
    showBankPropsComment,
    bankProps,
    setBankProps,
    setModal,
    modal
}) => {
    return (
        <div className="fix">
            {showBankPropsComment && <div className="input-valid valid-blue" style={{ margin: "0px" }}>
                <div className="input-comment-only">
                    <span className="input-comment-only-title">Комментарий ЕМГ: <span className="input-comment-text">{showBankPropsComment}</span></span>
                </div>
            </div>}

            <PreviewBankProps bankProps={bankProps} setBankProps={setBankProps} setModal={setModal} modal={modal}/>

            <div className="button-group">
                <Button text={"Добавить расчетный счет"} onClick={() => { setModal({ ...modal, modalBankPropsResume: true }) }} disabled={false} />
            </div>
        </div>
    );
};

export default BankProps;
