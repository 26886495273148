import { IBankProps } from "../../../../../../../types";
import ResultBankProps from "./component/ResultBankProps";

interface FormLineType {
    bankProps: IBankProps[];
}

const ResultBankPropsGroup: React.FC<FormLineType> = ({
    bankProps
}) => {
    return (
        <div>
            {bankProps.map((checkingАccount: IBankProps, key: any) => (
                checkingАccount.bankName && <ResultBankProps key={key} checkingАccount={checkingАccount}/>
            ))}
        </div>
    );
};

export default ResultBankPropsGroup;
