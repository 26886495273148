import { IContact } from "../../../../../../types";
import useSVG from "../../../../../Data/useSVG";

interface IPreContactProps {
    contact: IContact;
    handleDeleteClick: any;
    handleEditClick: any;
    index: number;
    isHeadContact: boolean;
}

const PreContact: React.FC<IPreContactProps> = ({
    contact,
    handleDeleteClick,
    handleEditClick,
    isHeadContact,
    index
}) => {

    const { iconContactPrew, iconHeadContactPrew } = useSVG();

    const { id, fullName, position, phoneNumber, email, comment } = contact;

    return (
        <div className="prew">
            <div className="prew-header">
                {isHeadContact ? iconHeadContactPrew : iconContactPrew}
                <div className="prew-header-text">
                    {fullName}
                    <div className="prew-info">
                        {position}<div />
                        {phoneNumber && <div>тел. {phoneNumber}</div>}
                        {email}<div />
                        {comment}<div />
                    </div>
                </div>
            </div>

            {handleDeleteClick && <div className="prew-group-btn">
                <div className="prew-button" onClick={() => handleEditClick(index)}>Изменить контакт</div>
                <div className={`prew-button ${id === 0 ? "" : "disabled"}`}
                    onClick={id === 0 ? () => handleDeleteClick(index) : undefined}
                >
                    Удалить контакт
                </div>
            </div>}

            {handleDeleteClick && <div className="prew-line" />}
            {/* {(!props.handleDeleteClick && props.contactsLength !== props.index) && <div className="_result-line" />} */}

        </div>
    );
};

export default PreContact;
