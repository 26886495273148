import { applyMiddleware, compose, createStore } from "redux";
import { rootReducer } from "./reducers";
import createSagaMiddleware from 'redux-saga';
import { sagaWatcher } from "./saga/dadataSaga";
import { sagaGetWatcher } from "./saga/contragentGetSaga";

const sagaMiddleware = createSagaMiddleware()

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {}
export const store = createStore(
    rootReducer,
    initialState,
    // compose(applyMiddleware(sagaMiddleware),
    //     composeEnhancers())
    composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(sagaWatcher)
sagaMiddleware.run(sagaGetWatcher)