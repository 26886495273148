import { Button } from "../../../../../Shared";

interface IResidentProps {
    setResidentStatus: (residentStatus: boolean) => void;
    setFormStatus: any;
}

const Resident: React.FC<IResidentProps> = ({
    setResidentStatus,
    setFormStatus
}) => {
    return (
        <div className="button-group">

            <Button text={"Резидент"} onClick={() => { setResidentStatus(true); setFormStatus(2) }} disabled={false} />

            <Button text={"Нерезидент"} onClick={() => { setResidentStatus(false); setFormStatus(2) }} disabled={false} />

        </div>
    );
};

export default Resident;
