import useSVG from "../../Data/useSVG";

interface ICheckBoxSquareSmallProps {
    setCheck: (value: boolean) => void,
    useCheck: boolean,
    text?: string,
    id?: string,
    actionCheck?: () => void;
    titleContext?: string;
}

const CheckBoxSquareSmall: React.FC<ICheckBoxSquareSmallProps> = ({
    setCheck,
    useCheck,
    text,
    id,
    actionCheck,
    titleContext
}) => {

    const { iconCheckOff, iconCheckOn } = useSVG();

    const handleOnClick = () => {
        setCheck(!useCheck);
        if (actionCheck) {
            actionCheck();
        }
    }

    return (
        <div className='checkbox-unit' title={titleContext}>
            <div className="checkbox-square" onClick={handleOnClick} id={id}>
                {!useCheck && iconCheckOff}
                {useCheck && iconCheckOn}
            </div>
            <label className='checkbox-text-small' htmlFor={id} onClick={handleOnClick}>{text}</label>
        </div>
    );
};

export default CheckBoxSquareSmall;
