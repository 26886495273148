import "../../../../../../style/prew.css";
import { IFiles } from "../../../../../../types";
import PreFile from "./PreFile";

interface IPreviewFilesProps {
    files: IFiles;
}

const PreviewFiles: React.FC<IPreviewFilesProps> = ({
    files
}) => {

    const preFilesData = [
        { text: "Протокол или решение подтверждающие полномочия", file: files.genManager },
        { text: "Свидетельство ИНН", file: files.inn },
        { text: "Квитанция о приеме налоговой отчетности", file: files.reportReceipt },
        { text: "Доверенность на подписанта", file: files.proxySigner },
        { text: "Карточка компании", file: files.enterpriseCard },
        { text: "Уведомление о возможности применения УСН", file: files.usn },
        { text: "Свидетельство о регистрации ИП", file: files.individualRegist },
        { text: "Паспорт", file: files.passport },
        { text: "СНИЛС", file: files.snils },
        { text: "Справка о постановке на учет", file: files.regCertificate },
        { text: "Реквизиты", file: files.props },
        { text: "Титульный лист отчета по страховым взносам", file: files.reportInsurance },
        { text: "Документ, подтверждающий статус налогового резидента в стране регистрации", file: files.taxNonResidentStatus }
    ];

    return (
        <div id="prewGroup" className="prew-group">
            {preFilesData.map(({ text, file }, index) => (
                file.value && <PreFile key={index} text={text} file={file} />
            ))}
        </div>
    );
};

export default PreviewFiles;
