import { useState } from "react";


const useSelectContragent = () => {

    const [showLegalStatus, setLegalStatus] = useState(0);
    const [showResidentStatus, setResidentStatus] = useState(true);

    const [useClient, setClientStatus] = useState(false);
    const [useProvider, setProviderStatus] = useState(false);

    return {
        showLegalStatus, setLegalStatus,
        showResidentStatus, setResidentStatus,
        useClient, setClientStatus,
        useProvider, setProviderStatus
    }
}

export default useSelectContragent;