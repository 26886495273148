import { DadataActionTypes } from "../types/dadata";
import { put, call, takeEvery } from "redux-saga/effects";
import { DadataBankActionTypes } from "../types/dadataBank";

export function* sagaWatcher() {
    yield takeEvery(DadataActionTypes.FETCH_DADATA, sagaWorker)
    yield takeEvery(DadataBankActionTypes.FETCH_DADATA_BANK, sagaBankWorker)
}

var url_Bank = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
var url_Inn = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";

function* sagaWorker(inputINN: any): any {
    try {
        const data = yield call(getRequest, inputINN.vInn, url_Inn);

        if (data.suggestions.length > 1) {
            const request = data.suggestions;
            const payload = request.map((contragent:any) => ({
                address: contragent?.data.address.data.source,
                kpp: contragent?.data.kpp,
                ogrn: contragent?.data.ogrn,
                email: contragent?.data.emails,
                phone: contragent?.data.phones,
                companyName: contragent?.data.name.short_with_opf,
                companyShortName: contragent?.data.name.short,
                director: contragent?.data.management?.name,
                position: contragent?.data.management?.post,
                fio: {
                    firstname: contragent?.data.fio?.name,
                    surname: contragent?.data.fio?.surname,
                    patronymic: contragent?.data.fio?.patronymic
                }
            }));
            yield put({
                type: DadataActionTypes.FETCH_DADATA_SUCCESS,
                payload
            });
        } else {
            const request = data.suggestions[0].data;
            const payload = {
                address: request?.address.data.source,
                kpp: request?.kpp,
                ogrn: request?.ogrn,
                email: request?.emails,
                phone: request?.phones,
                companyName: request?.name.short_with_opf,
                companyShortName: request?.name.short,
                director: request?.management?.name,
                position: request?.management?.post,
                fio: {
                    firstname: request?.fio?.name,
                    surname: request?.fio?.surname,
                    patronymic: request?.fio?.patronymic
                }
            };
            yield put({
                type: DadataActionTypes.FETCH_DADATA_SUCCESS,
                payload
            });
        }
    } catch (e) {
        yield put({
            type: DadataActionTypes.FETCH_DADATA_ERROR,
            payload: "Произошла ошибка при заполнении данных из налоговой"
        });
    }
}

function* sagaBankWorker(inputBank: any): any {
    try {
        const data = yield call(getRequest, inputBank.vBank, url_Bank);
        const request = Object.keys(data.suggestions).map(function (n) {
            return {
                id: n, 
                value: data.suggestions[n].value, 
                bankCityName: data.suggestions[n].data.payment_city,
                bic: data.suggestions[n].data.bic,
                corrAccount: data.suggestions[n].data.correspondent_account,
            }
            
        })
        yield put({
            type: DadataBankActionTypes.FETCH_DADATA_BANK_SUCCESS,
            payload: { request }
        })
    } catch (e) {

        yield put({
            type: DadataBankActionTypes.FETCH_DADATA_BANK_ERROR,
            payload: "Произошла ошибка при заполнении данных из налоговой"
        })
    }
}

async function getRequest(pattern: any, url: string) {
    var token = "1ca666ef1f6bea733eb4e11763e6519a963dd21c";
    var query = pattern;

    var options: any = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
        },
        body: JSON.stringify({ query: query, count: 100 })
    }

    const data = await fetch(url, options)
        .then(response => response.json())
    return data;
}