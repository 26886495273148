import { IContact } from "../../../../../../../../types";
import useSVG from "../../../../../../../Data/useSVG";

interface FormLineType {
    contact: IContact;
}

const ResultContacts: React.FC<FormLineType> = ({
    contact
}) => {

    const { iconContactPrew } = useSVG();

    return (
        <div className="prew-result">
            <div className="result-line" />
            <div className="prew prew-group">
                <div className="prew-header">
                    {iconContactPrew}
                    <div className="prew-header-text">
                        {/* {contact.lastname} {contact.firstname} {contact.patronymic} */}
                        {contact.fullName}
                        <div className="prew-info">
                            {contact.position}<div />
                            {contact.phoneNumber && <div>тел. {contact.phoneNumber}</div>}
                            {contact.email}<div />
                            {contact.comment}<div />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultContacts;
