import { FieldDefinitionType, ICompany, IInn } from "../../../../../types";
import useSVG from "../../../../Data/useSVG";
import Frame from "../../../../tools/Frame/Frame";
import Entity from "./component/Entity";

interface IEntityFormProps {
    loading: boolean;
    inn: IInn;
    showResidentStatus: boolean;
    company: ICompany;
    useAddress: boolean;
    setAddressStatus: any;
    edo: FieldDefinitionType;
    useTrek: any;
    setBranch: (branch: string)=> void;
}

const EntityForm: React.FC<IEntityFormProps> = ({
    loading,
    inn,
    showResidentStatus,
    company,
    useAddress,
    setAddressStatus,
    edo,
    useTrek,
    setBranch
}) => {

    const { iconRec } = useSVG();
    return (
        <Frame
            icon={iconRec}

            header={"Реквизиты"}

            body={
                <Entity
                    loading={loading}
                    inn={inn}
                    showResidentStatus={showResidentStatus}
                    company={company}
                    useAddress={useAddress}
                    setAddressStatus={setAddressStatus}
                    edo={edo}
                    useTrek={useTrek}
                    setBranch={setBranch}
                />
            }
        />
    );
};

export default EntityForm;
