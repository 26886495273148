export interface ContragentState {
    loadingCo: boolean;
    errorCo: null | string;
    contragent: any;
}

export enum ContragentActionTypes {
    FETCH_CONTRAGENT = 'FETCH_CONTRAGENT',
    FETCH_CONTRAGENT_ERROR = 'FETCH_CONTRAGENT_ERROR',
    FETCH_CONTRAGENT_SUCCESS = 'FETCH_CONTRAGENT_SUCCESS'
}

interface FetchContragentAction {
    type: ContragentActionTypes.FETCH_CONTRAGENT;
}

interface FetchContragentErrorAction {
    type: ContragentActionTypes.FETCH_CONTRAGENT_ERROR;
    payload: string;
}

interface FetchContragentSuccessAction {
    type: ContragentActionTypes.FETCH_CONTRAGENT_SUCCESS;
    payload: any;
}

export type ContragentAction = FetchContragentAction | FetchContragentErrorAction | FetchContragentSuccessAction

