import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { ContragentActionTypes } from "../../store/types/contragentTypes";
import useModalContragent from "../Data/useModalContragent";
import Final from "./Final/Final";
import NotAvailable from "./Final/NotAvailable";
import Form from "./Form/Form";
import Modal from "./Modal/Modal";
import { Loader } from "../../Shared/ui/Loader";
import { API_postNewCo } from "../../API/fields/post";
import { IContragentDataModel } from "../../models";

interface IMainProps {
    showForm: number;
    setFormStatus: any;
    setValidFormStatus: any;
}

const Main: React.FC<IMainProps> = ({
    showForm,
    setFormStatus,
    setValidFormStatus,
}) => {

    const { modal, setModal, contacts, setContacts, bankProps, setBankProps, headContactFullname, setHeadContactFullname } = useModalContragent();

    const { contragent, loadingCo } = useTypedSelector((state) => state.contragentRequest);
    const [loading, setLoading] = useState<boolean>(true);
    const [showNewCoHash, setNewCoHash] = useState("")

    window.onkeydown = function (event) {
        if (event.keyCode === 27) {
            setModal({ ...modal, modalContactsResume: false, modalBankPropsResume: false })
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        let newCoHash = window.location.pathname.split("/")[1];
        setNewCoHash(newCoHash);
        dispatch({ type: ContragentActionTypes.FETCH_CONTRAGENT, newCoHash });
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Form]);

    useEffect(() => {
        if (contragent?.legalStatus > 0) setFormStatus(3);
        if (contragent === 408) setFormStatus(6);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contragent])

    const onSendJSON = async (ContragentJson: IContragentDataModel) => {
        setLoading(true)
        const statusAPI = await API_postNewCo(ContragentJson, showNewCoHash);
        if (statusAPI === 408) {
            setFormStatus(6);
        }
        else {
            setFormStatus(5);
        }
        setLoading(false);
    }

    return (
        <div className="main">
            {(loadingCo || loading) && <Loader />}
            {showForm !== 5 && showForm !== 6 && !loading && !loadingCo && <Form
                setModal={setModal}
                modal={modal}
                showForm={showForm}
                setFormStatus={setFormStatus}
                contacts={contacts}
                setContacts={setContacts}
                bankProps={bankProps}
                setBankProps={setBankProps}
                setValidFormStatus={setValidFormStatus}
                headContactFullname={headContactFullname}
                setHeadContactFullname={setHeadContactFullname}
                showNewCoHash={showNewCoHash}
                onSendJSON={onSendJSON}
            />}
            <Modal
                modal={modal} setModal={setModal}
                contacts={contacts} setContacts={setContacts}
                bankProps={bankProps} setBankProps={setBankProps}
                headContactFullname={headContactFullname} setHeadContactFullname={setHeadContactFullname}
            />
            {showForm === 5 && !loading && !loadingCo && <Final />}
            {showForm === 6 && !loading && !loadingCo && <NotAvailable />}
        </div>
    );
};

export default Main;
