import React from "react";
import "./path.css";
import useSVG from "../../../Data/useSVG";
interface FormLineType {
    showForm: number;
    setFormStatus: any;
    showLegalStatus: number;
    showResidentStatus: boolean;
    useClient: boolean;
    useProvider: boolean;
}

const Path: React.FC<FormLineType> = ({
    showForm, setFormStatus,
    showLegalStatus,
    showResidentStatus,
    useClient,
    useProvider
}) => {

    const { iconBack } = useSVG();

    return (
        <div className="title">
            {showForm === -1}
            {showForm === 0 && <div className="title-first-grid">
                <div className="title-first-text">Добавление нового партнера Европейской медиагруппы</div>
            </div>}
            {showForm !== 0 && <div className="title-grid">

                {showForm !== -1 && showForm < 5 && <div className="path-group">
                    {showForm === 4 && <button title="Назад" className="chevron-left" onClick={() => setFormStatus(showForm - 1)}>{iconBack}</button>}
                    <div className="path">

                        {showForm < 4 && <div className="path-strong" > Новый партнер </div>}

                        {showForm < 4 && <div className="path-name">
                            {(showLegalStatus === 1 && "/ Юридическое лицо ") ||
                                (showLegalStatus === 2 && "/ Индивидуальный предприниматель ") ||
                                (showLegalStatus === 3 && "/ Физическое лицо или самозанятый ")}
                        </div>}

                        {showForm !== 1 && showForm < 4 && <div >
                            {((showResidentStatus === true && "/ Резидент ") || (showResidentStatus === false && "/ Нерезидент "))}
                        </div>}

                        {showForm === 3 && showForm < 4 && <div >
                            {useClient && !useProvider && " / Клиент"}
                            {useProvider && !useClient && " / Поставщик"}
                            {useProvider && useClient && " / Поставщик, Клиент"}
                        </div>}


                        {showForm === 4 && <div onClick={() => setFormStatus(showForm - 1)} className="path-strong path-action">
                            Назад к редактированию
                        </div>}

                    </div>
                </div>}
                {showForm === 3 && <div className="fix"></div>}
            </div>}
        </div>
    );
};

export default Path;
