import Frame from "../../tools/Frame/Frame"
import "../../../style/modal.css";
import { SetStateAction, useEffect } from "react";
import ModalBankProps from "./BankProps/ModalBankProps";
import ModalContacts from "./Contacts/ModalContacts";
import useSVG from "../../Data/useSVG";
import { IBankProps, IContact, IModal } from "../../../types";

interface IModalProps {
    modal: IModal;
    setModal: (value: SetStateAction<IModal>) => void;
    contacts: IContact[];
    setContacts: (bankProps: IContact[]) => void;
    bankProps: IBankProps[];
    setBankProps: (bankProps: IBankProps[]) => void;
    headContactFullname?: string;
    setHeadContactFullname: (value: string) => void;
}


const Modal: React.FC<IModalProps> = ({
    modal,
    setModal,
    contacts,
    setContacts,
    bankProps,
    setBankProps,
    headContactFullname,
    setHeadContactFullname
}) => {

    
    const { iconBank, iconContact } = useSVG();

    useEffect(() => {
        if (modal.modalBankPropsResume || modal.modalContactsResume === true) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "auto";
        }
    }, [modal.modalBankPropsResume || modal.modalContactsResume])


    return (
        <div className={`modal-wrapper ${(modal.modalBankPropsResume || modal.modalContactsResume) ? 'open' : 'close'}`}>
            <form>
                {modal.modalBankPropsResume && <Frame icon={iconBank} header={"Новый расчетный счет"} body={
                    <ModalBankProps
                        modal={modal}
                        setModal={setModal}
                        bankProps={bankProps}
                        setBankProps={setBankProps}
                    />
                } />}

                {modal.modalContactsResume && <Frame icon={iconContact} header={"Новый контакт"} body={
                    <ModalContacts
                        modal={modal}
                        setModal={setModal}
                        contacts={contacts}
                        setContacts={setContacts}
                        headContactFullname={headContactFullname} 
                        setHeadContactFullname={setHeadContactFullname}
                    />
                } />}
            </form>
        </div>
    )
}

export default Modal