import { IBankProps, IModal } from "../../../../../types";
import PreBankProps from "./component/PreBankProps";

interface IPreviewBankPropsProps {
    bankProps: IBankProps[];
    setBankProps: (bankProps: IBankProps[]) => void;
    setModal: (value: IModal) => void;
    modal: IModal;
}

const PreviewBankProps: React.FC<IPreviewBankPropsProps> = ({
    bankProps, setBankProps,
    setModal, modal
}) => {

    const handleDeleteClick = (index: number) => {
        const newBankProps = bankProps.filter((_, i) => i !== index);
        setBankProps(newBankProps);
    }

    const handleEditClick = (index: number) => {
        setModal({ ...modal, modalBankPropsResume: true, modalEdit: true, modalID: index })
    }

    return (
        <div className="prew-group">

            {bankProps.map((checkingАccount: IBankProps, index: number) => (
                checkingАccount.bankName &&
                <PreBankProps
                    index={index}
                    key={index}
                    checkingАccount={checkingАccount}
                    handleDeleteClick={handleDeleteClick}
                    handleEditClick={handleEditClick}
                />
            ))}

        </div>
    );
};

export default PreviewBankProps;
