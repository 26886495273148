import { useEffect, useState } from "react";
// import "../../../../style/input.css";
import "../../../../../style/inputSelect.css";

interface FormLineType {
    name: any;
    useValue: any;
    dadataBank: any;
    label: string;
    placeholder: string;
    bank_city: any;
    bik: any;
    correspondent_account: any;
    setAutoInput: any;
    setCorrAccount: any;
    setID: any;
}

const InputSelect: React.FC<FormLineType> = ({
    name,
    useValue,
    dadataBank,
    label,
    placeholder,
    bank_city,
    bik,
    correspondent_account,
    setAutoInput,
    setCorrAccount,
    setID
}) => {

    const [showActiveList, setActiveList] = useState(false);

    let elem: any = document.querySelector('#option_box');

    const openList = () => {

        if (useValue.value.length > 1) {
            if (!showActiveList) {
                elem.parentElement.classList.add('is-active')
                setActiveList(true);
            }
        }
        else {
            closeList();
            setActiveList(false);
        }
    }

    const closeList = () => {
        if (showActiveList) {
            let select = elem.closest('.select');
            select.classList.remove('is-active');
            setActiveList(false);
        }
    }

    const select = (bank: any) => {
        setID(bank.id);
        useValue.set(bank.value);
        bank_city.set(bank.bankCityName);
        bik.set(bank.bic);
        correspondent_account.set(bank.corrAccount);
        closeList();
    }
    useEffect(() => {
        if (showActiveList) {
            document.addEventListener('click', function (event: any) {
                if (!elem?.contains(event.target)) {
                    closeList();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showActiveList])

    return (
        <div className={useValue.isValid}>
            <div className="input-frame">

                <div className="input-title">
                    <label className="input-title-text" htmlFor={name}>
                        {label}
                        {!useValue.disableValid && useValue.isRequired && <label htmlFor={name} className={"input-title-star"}> *</label>}
                    </label>
                    {((!useValue.inputValid && useValue.isDirty)) && <span className={"input-valid-text"} style={{ color: useValue.colorError }}>{useValue.error}</span>}
                </div>

                <div className="select" onChange={openList}>

                    <input name={name}
                        id="option_box"
                        className="input" type="text"
                        placeholder={placeholder}
                        {...useValue.bind}
                        //  disabled={disabled}
                        spellCheck="off" autoComplete="off" />

                    <div className="select__body">
                        {dadataBank?.request?.map((bank: any, key: any) => {
                            return <div className="select__item" key={key} onClick={() => select(bank)}>
                                <div className="select_item-text">{bank.value}</div>
                                <div className="select_item-text_bic">БИК {bank.bic}</div>
                            </div>;
                        })}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default InputSelect;
