interface IFrameProps {
    icon: any,
    header: any,
    body: any,
    poptext?: any
    poptextResult?: any
}

const Frame: React.FC<IFrameProps> = ({
    icon,
    header,
    body,
    poptext,
    poptextResult
}) => {
    return (
        <div className={poptext ? "frame-fix" : "frame"}>
            <div className="frame-header">
                <div className="frame-header-icon">{icon}</div> <div className="frame-header-name">{header}</div>
            </div>
            {poptext && <div className="frame-poptext"><div />{poptext}</div>}
            <div className="frame-line"></div>
            <div className="frame-input">{body}</div>
        </div>
    );
};

export default Frame;
