import { ACCEPT_FORMAT_FILE } from "../../../../Shared/config";
import { IFiles } from "../../../../types";
import useSVG from "../../../Data/useSVG";
import Frame from "../../../tools/Frame/Frame";
import Documents from "./component/Documents";

interface IDocumentsFormProps {
    files: IFiles;
    showLegalStatus: number;
    showDocComment: string;
    showResidentStatus: boolean;
}

const DocumentsForm: React.FC<IDocumentsFormProps> = ({
    files,
    showLegalStatus,
    showDocComment,
    showResidentStatus
}) => {

    const { iconDoc } = useSVG();

    return (
        <div>
            <Frame
                icon={iconDoc}

                header={"Документы"}

                body={
                    <Documents
                        files={files}
                        showLegalStatus={showLegalStatus} showDocComment={showDocComment}  showResidentStatus={showResidentStatus}
                    />
                }

                poptext={<div className="frame-poptext-text">доступные форматы {ACCEPT_FORMAT_FILE}</div>}
            />
        </div>
    );
};

export default DocumentsForm;
