import { createAxiosRequest } from "../api";

export const API_deleteFile = async (id: string, value: string, newCoHash: string) => {
    return createAxiosRequest({
        url: `File/${newCoHash}`,
        method: "delete",
        params: { fileName: `${id}_${value}`}
    });
};

export const API_addFile = async (newCoHash: string, formData: FormData) => {
    return createAxiosRequest({
        url: `File/${newCoHash}`,
        method: "post",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: formData
    });
};
