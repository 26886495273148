import {combineReducers} from "redux";
import { dadataReducer } from "./dadataReducer";
import { dadataBankReducer } from "./dadataBankReducer";
import { contragentReducer } from "./contragentReducer";

export const rootReducer = combineReducers({
    request: dadataReducer,
    dadataBankRequest: dadataBankReducer,
    contragentRequest: contragentReducer
})

export type RootState = ReturnType <typeof rootReducer>