import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../store/hooks/useTypedSelector";
import { DadataActionTypes } from "../../../store/types/dadata";
import useInputContragent from "../../Data/useInputContragent";
import useSelectContragent from "../../Data/useSelectContragent";
import BankPropsForm from "./BankProps/BankPropsForm";
import ContactsForm from "./Contacts/ContactsForm";
import DocumentsForm from "./Documents/DocumentsForm";
import EntityForm from "./FormPerson/Entity/EntityForm";
import IndividualForm from "./FormPerson/Individual/IndividualForm";
import ResultForm from "./Result/ResultForm";
import Path from "./Path/Path";
import SelectContragent from "./SelectContragent/SelectContragent";
import "../../../style/form.css";
import useGetContragent from "../../Data/useGetContragent";
import { IBankProps, IContact, IModal } from "../../../types";
import { IContragentDataModel } from "../../../models";

interface IFormProps {
  setModal: (value: IModal) => void;
  modal: IModal;
  showForm: number;
  setFormStatus: any;
  contacts: IContact[];
  setContacts: (contacts: IContact[]) => void;
  bankProps: IBankProps[];
  setBankProps: (contacts: IBankProps[]) => void;
  setValidFormStatus: any;
  headContactFullname?: string;
  setHeadContactFullname: (value: string) => void;
  showNewCoHash: string;
  onSendJSON: (ContragentJson: IContragentDataModel) => void;
}

const Form: React.FC<IFormProps> = ({
  setModal,
  modal,
  showForm,
  setFormStatus,
  contacts,
  setContacts,
  bankProps,
  setBankProps,
  setValidFormStatus,
  headContactFullname,
  setHeadContactFullname,
  showNewCoHash,
  onSendJSON
}) => {
  const [useAddress, setAddressStatus] = useState(false);

  const [useTrek, setTrek] = useState(<></>);
  const [httpError, setHttpError] = useState(0);
  const [currentBranch, setBranch] = useState("")
  const {
    showLegalStatus, setLegalStatus,
    showResidentStatus, setResidentStatus,
    useClient, setClientStatus,
    useProvider, setProviderStatus
  } = useSelectContragent();

  const { files, fields } = useInputContragent(showLegalStatus, showResidentStatus, showNewCoHash, setHttpError);
  const { showOtherComment } = useGetContragent(
    files, fields,
    setResidentStatus, setClientStatus,
    setProviderStatus, setLegalStatus,
    setContacts, setBankProps,
    setHeadContactFullname
  );
  const { dadata, loading } = useTypedSelector((state) => state.request); //{error}


  const dispatch = useDispatch();
  useEffect(() => {
    if (httpError === 408) setFormStatus(6);
  }, [httpError])

  useEffect(() => {
    if (fields.inn.isResident && showLegalStatus !== 3) {
      setTrek(<div className="input-valid-inn" onClick={() => { setFormStatus(2); setResidentStatus(false); fields.inn.setResident(false) }}>Перейти к нерезиденту</div>)
    }
    else setTrek(<></>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.inn.error])

  useEffect(() => {
    if (dadata?.companyName !== undefined) setBranch(dadata.kpp)
    let currentFilial = dadata
    if (Array.isArray(dadata))
      currentFilial = dadata.find((branch: any) => branch.kpp === currentBranch)

    if (currentFilial?.companyName !== undefined) {
      fields.company.address.set(currentFilial?.address);
      fields.phone.set(currentFilial?.phone);
      fields.email.set(currentFilial?.email);
      fields.company.kpp.set(currentFilial?.kpp);
      fields.company.ogrn.set(currentFilial?.ogrn);
      fields.company.companyName.set(currentFilial?.companyName);
      if (currentFilial.companyShortName === null)
        fields.company.companyShortName.set(currentFilial?.companyName);
      else
        fields.company.companyShortName.set(currentFilial?.companyShortName);

      if (showLegalStatus === 2 || showLegalStatus === 3) {
        // fields.fullName.firstname.set(dadata?.fio?.firstname);

        fields.fullName.firstname.set(currentFilial?.fio?.firstname);
        fields.fullName.surname.set(currentFilial?.fio?.surname);
        fields.fullName.patronymic.set(currentFilial?.fio?.patronymic);
      }

      if (currentFilial?.director) {
        if (!contacts.some(contact => contact.fullName === currentFilial.director)) {
          const preNewContacts = [...contacts];
          // const index = preNewContacts.findIndex(n => n.id === 0);
          // if (index !== -1) {
          //   preNewContacts.splice(index, 1);
          // }

          const newContact: IContact = {
            id: 0,
            position: currentFilial?.position,
            fullName: currentFilial?.director,
            phoneNumber: "",
            email: "",
            comment: ""
          };
          const newContacts = [...preNewContacts, newContact];
          setContacts(newContacts);
        }

        setHeadContactFullname(currentFilial?.director);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch, dadata]);

  const handlerClear = async (event: any = true) => {
    setAddressStatus(false);
    setClientStatus(false);
    setProviderStatus(false);
    fields.fullName.firstname.clear();
    fields.fullName.surname.clear();
    fields.fullName.patronymic.clear();
    fields.passport.clear();
    if (event) {
      fields.inn.clear();
      dispatch({ type: DadataActionTypes.FETCH_DADATA_ERROR, payload: null });
    }
    fields.phone.clear();
    fields.email.clear();
    fields.company.address.clear();
    fields.comment.clear();
    fields.company.kpp.clear();
    fields.company.ogrn.clear();
    fields.company.companyName.clear();
    fields.company.companyShortName.clear();
    fields.company.address2.clear();
    fields.edo.clear();
    files.genManager.clear(false);
    files.proxySigner.clear(false);
    files.inn.clear(false);
    files.enterpriseCard.clear(false);
    files.reportReceipt.clear(false);
    files.usn.clear(false);
    files.individualRegist.clear(false);
    files.passport.clear(false);
    files.snils.clear(false);
    files.regCertificate.clear(false);
    files.props.clear(false);
    setContacts([]);
    setBankProps([]);
  };

  useEffect(() => {
    if ((showLegalStatus === 1 && fields.inn.inputValid && fields.company.companyName.inputValid && fields.company.companyShortName.inputValid &&
      (!showResidentStatus || fields.company.kpp.inputValid) && fields.company.address.inputValid
    )
      ||
      (showLegalStatus === 2 && fields.inn.inputValid && fields.fullName.surname.inputValid && fields.fullName.firstname.inputValid
      )
      ||
      (
        showLegalStatus === 3 && fields.inn.inputValid && fields.fullName.surname.inputValid && fields.fullName.firstname.inputValid
      )
    ) {
      setValidFormStatus(false)
    }
    else {
      setValidFormStatus(true)
    }
  })
  return (
    <div className="form">
      <Path
        showForm={showForm}
        setFormStatus={setFormStatus}
        showLegalStatus={showLegalStatus}
        showResidentStatus={showResidentStatus}
        useClient={useClient}
        useProvider={useProvider} />

      <div className="frame-grid">
        {showForm === -1 && <div className="frame-grid-center">
          <p>Данный портал используется для ввода информации о контрагентах Европейской Медиагруппы.</p>
          <p>Для получения доступа обратитесь к менеджеру ЕМГ, который направил вам эту ссылку, или напишите сообщение на
            почту&nbsp;
            <a href={"mailto:newco@emg.fm?subject=Подключение к NewCo"}>newco@emg.fm</a></p>
        </div>}

        {showForm !== -1 && showForm < 3 &&
          <SelectContragent
            showForm={showForm}
            setFormStatus={setFormStatus}
            setLegalStatus={setLegalStatus}
            setResidentStatus={setResidentStatus}
            setClientStatus={setClientStatus}
            useClient={useClient}
            setProviderStatus={setProviderStatus}
            useProvider={useProvider}
            handlerClear={handlerClear} />
        }

        {showForm === 3 && <div className="fix">
          {showLegalStatus === 1 &&
            <EntityForm
              setBranch={setBranch}
              loading={loading}
              inn={fields.inn}
              showResidentStatus={showResidentStatus}
              company={fields.company}
              useAddress={useAddress}
              setAddressStatus={setAddressStatus}
              edo={fields.edo}
              useTrek={useTrek} />
          }
          {showLegalStatus !== 1 &&
            <IndividualForm
              inn={fields.inn}
              address={fields.company.address}
              address2={fields.company.address2}
              edo={fields.edo}
              loading={loading}
              passport={fields.passport}
              ogrn={fields.company.ogrn}
              phone={fields.phone}
              email={fields.email}
              fullName={fields.fullName}
              showLegalStatus={showLegalStatus}
              useTrek={useTrek}
              showResidentStatus={showResidentStatus}
            />
          }
        </div>}
        {showForm === 3 && <div className="fix">
          <div className="frame-group">

            <BankPropsForm
              showBankPropsComment={showOtherComment.showBankPropsComment}
              bankProps={bankProps} setBankProps={setBankProps}
              setModal={setModal} modal={modal}
            />

            {showLegalStatus !== 3 &&
              <ContactsForm
                showContactsComment={showOtherComment.showContactsComment}
                contacts={contacts} setContacts={setContacts}
                setModal={setModal} modal={modal}
                headContactFullname={headContactFullname}
                setHeadContactFullname={setHeadContactFullname}
              />
            }

            <DocumentsForm
              files={files}
              showLegalStatus={showLegalStatus}
              showDocComment={showOtherComment.showDocComment}
              showResidentStatus={showResidentStatus}
            />

          </div>
        </div>}

        {showForm === 4 && <ResultForm
          setFormStatus={setFormStatus} showNewCoHash={showNewCoHash}
          showLegalStatus={showLegalStatus} showResidentStatus={showResidentStatus}
          files={files} fields={fields}
          contacts={contacts} bankProps={bankProps}
          useClient={useClient} useProvider={useProvider} onSendJSON={onSendJSON}
          headContactFullname={headContactFullname}
        />
        }

      </div>
    </div>
  );
};

export default Form;
