import { FC } from "react";
import "./header.css";

interface IHeaderProps {
    showForm: number;
    setFormStatus: any;
    showValidForm: boolean;
}

const Header: FC<IHeaderProps> = ({
    showForm,
    setFormStatus,
    showValidForm
}) => {

    return (
        <div className={(showForm === 5 || showForm === 6) ? 'header-final' : 'header'}>
            <div className="header-logo">
                <img alt="EMG logo" src="/images/EMG_logo.png" />
                <div className='logo-line'></div>
                <div className='logo-text'>Партнеры</div>
            </div>
            {showForm === 3 &&
                <button className="header-button"
                    onClick={() => setFormStatus(4)}
                    disabled={showValidForm}
                >
                    <div className="header-button-text">
                        Продолжить
                    </div>
                </button>}
        </div>
    );
};

export default Header;