// import "../../../style/input.css";

interface FormLineType {
    useValue: any,
    label?: string,
    name?: string,
    placeholder?: string,
    disabled?: boolean,
    loading?: any
}

const FieldInputAuto: React.FC<FormLineType> = ({ useValue, label, name, placeholder, disabled, loading }) => {

    return (
        <div className={useValue.isValid}>
            <div className="input-frame">
                <div className="input-title">
                    <label className="input-title-text" htmlFor={name}>
                        {label}
                        {!useValue.disableValid && useValue.isRequired && <label htmlFor={name} className={"input-title-star"}> *</label>}
                    </label>
                </div>

                {((!useValue.inputValid && useValue.isDirty)) && <span className={"input-valid-text"} style={{ color: useValue.colorError }}>{useValue.error}</span>}

                {!useValue.value && <div className="input-frame-auto">Подтянем автоматически, когда заполните поле "Банк"</div>}

                {useValue.value && <input name={name}
                    id={name}
                    className="input"
                    type="text"
                    placeholder={placeholder}
                    {...useValue.bind}
                    disabled={disabled}
                />}
            </div>
        </div>
    );
};

export default FieldInputAuto;
