import { IFileInputState } from "../../../../../../../../types";
import useSVG from "../../../../../../../Data/useSVG";

interface FormLineType {
    text: string;
    file: IFileInputState;

}

const ResultFiles: React.FC<FormLineType> = ({
    text,
    file

}) => {

    const { iconDocPrew } = useSVG();

    return (
        <div className="prew-result">
            <div className="result-line" />
            <div className="prew prew-group">
                <div className="prew-header">
                    <div className="prew-check-circle">
                        {iconDocPrew}
                    </div>
                    <div className="prew-header-text">
                        {text}
                        <div className="prew-name">{file.value}</div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default ResultFiles;
