import { ChangeEvent, useState } from 'react';
import { useValidation } from './useValidation';
import { IValidations } from '../../../../types/IValidation';
// import "../style/input.css";

export function useInput(initialValue: string, validations?: IValidations, LegalStatus?: number, ResidentStatus?: boolean) {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const [showComment, setComment] = useState("");
    const [isDisabled, setDisabled] = useState(false)

    const valid = useValidation(value, validations, LegalStatus, ResidentStatus);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => { setValue(event.target.value) }

    const clear = () => {
        setValue('');
        setDirty(false)
    };
    const set = (e: any) => { setValue(e) };
    const onBlur = (e: any = true) => { setDirty(e) };

    const isValid: string = showComment ? "input-valid valid-blue" : 'input-valid';

    return {
        bind: { value, onChange, onBlur},
        value,
        clear,
        set,
        setComment,
        showComment,
        isDirty,
        isValid,
        onBlur,
        isDisabled,
        setDisabled,
        ...valid
    }
}