export interface DadataBankState {
    dadataBank: any;
    loading: boolean;
    error: null | string;
}

export enum DadataBankActionTypes {
    FETCH_DADATA_BANK = 'FETCH_DADATA_BANK',
    FETCH_DADATA_BANK_SUCCESS = 'FETCH_DADATA_BANK_SUCCESS',
    FETCH_DADATA_BANK_ERROR = 'FETCH_DADATA_BANK_ERROR'
}

interface FetchDadataBankAction {
    type: DadataBankActionTypes.FETCH_DADATA_BANK
    // payload: any;

}

interface FetchDadataBankErrorAction {
    type: DadataBankActionTypes.FETCH_DADATA_BANK_ERROR
    payload: string;
}

interface FetchDadataBankSuccessAction {
    type: DadataBankActionTypes.FETCH_DADATA_BANK_SUCCESS
    payload: any;
}

export type DadataBankAction = FetchDadataBankAction | FetchDadataBankErrorAction | FetchDadataBankSuccessAction