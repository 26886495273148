import { IFields, IFiles, TSetHttpError } from "../../types";
import { useFileInput } from "../Main/Form/hooks/useFileInput";
import { useInput } from "../Main/Form/hooks/useInput";

const useInputContragent = (showLegalStatus: number, showResidentStatus: boolean, showNewCoHash: string, setHttpError: TSetHttpError) => {

  const files: IFiles = {
    genManager: useFileInput("", { isTypeFile: true }, "docGenManager", showNewCoHash, setHttpError), //Протокол или решение, подтверждающее полномочия генерального директора;
    proxySigner: useFileInput("", { isTypeFile: true }, "docProxySigner", showNewCoHash, setHttpError), //Доверенность на подписанта (если документы подписывает доверенное лицо);
    inn: useFileInput("", { isTypeFile: true }, "docInn", showNewCoHash, setHttpError), //ИНН
    enterpriseCard: useFileInput("", { isTypeFile: true }, "docEnterpriseCard", showNewCoHash, setHttpError), //Полная карточка предприятия с реквизитами и указанием главного бухгалтера и его контактов
    reportReceipt: useFileInput("", { isTypeFile: true }, "docReportReceipt", showNewCoHash, setHttpError), //Квитанции о приеме налоговой отчетности - за последний отчетный период по НДС и Прибыли или квитанция по отчетности по УСН (при использовании УСН)
    usn: useFileInput("", { isTypeFile: true }, "docUsn", showNewCoHash, setHttpError), //При использовании УСН: уведомление о возможности применения упрощенной системы налогообложения
    individualRegist: useFileInput("", { isTypeFile: true }, "docIndividualRegist", showNewCoHash, setHttpError), //Свидетельство о регистрации ИП
    passport: useFileInput("", { isTypeFile: true }, "docPassport", showNewCoHash, setHttpError), //Паспорт
    snils: useFileInput("", { isTypeFile: true }, "docSnils", showNewCoHash, setHttpError), //СНИЛС
    regCertificate: useFileInput("", { isTypeFile: true }, "docRegCertificate", showNewCoHash, setHttpError), //Справка о постановке на учет;
    props: useFileInput("", { isTypeFile: true }, "docProps", showNewCoHash, setHttpError), //Реквизиты
    other: useFileInput("", { isTypeFile: true }, "docOther", showNewCoHash, setHttpError), //Дополнительные документы
    reportInsurance: useFileInput("", { isTypeFile: true }, "docReportInsurance", showNewCoHash, setHttpError), //Титульный лист отчета по страховым взносам
    taxNonResidentStatus: useFileInput("", { isTypeFile: true }, "docTaxNonResidentStatus", showNewCoHash, setHttpError), //Документ, подтверждающий статус налогового резидента в стране регистрации, для резидентов стран СНГ
  };

  const fields: IFields = {
    inn: useInput("", { isEmpty: true, isResident: true, isEntity: true, isIndividual: true, isNotLengthINN: true }, showLegalStatus, showResidentStatus),
    fullName: {
      firstname: useInput("", { isEmpty: true }),
      surname: useInput("", { isEmpty: true }),
      patronymic: useInput(""),
      value: useInput(""),
    },
    passport: useInput(""),
    phone: useInput("", { isPhone: true }),
    email: useInput("", { isEmail: true }),
    edo: useInput(""),
    company: {
      kpp: useInput("", { isEmpty: true }),
      ogrn: useInput(""),
      companyName: useInput("", { isEmpty: true }),
      companyShortName: useInput("", { isEmpty: true }),
      address: useInput("", { isEmpty: true }),
      address2: useInput(""),
    },
    comment: useInput(""),
  };


  return {
    files, fields
  }
}

export default useInputContragent;