export interface DadataState {
    loading: boolean;
    error: null | string;
    // dadata: any[];
    dadata: any;
}

export enum DadataActionTypes {
    FETCH_DADATA = 'FETCH_DADATA',
    FETCH_DADATA_ERROR = 'FETCH_DADATA_ERROR',
    FETCH_DADATA_SUCCESS = 'FETCH_DADATA_SUCCESS'
}

interface FetchDadataAction {
    type: DadataActionTypes.FETCH_DADATA;
}

interface FetchDadataErrorAction {
    type: DadataActionTypes.FETCH_DADATA_ERROR;
    payload: string;
}

interface FetchDadataSuccessAction {
    type: DadataActionTypes.FETCH_DADATA_SUCCESS;
    // payload: any[];
    payload: any;
}

export type DadataAction = FetchDadataAction | FetchDadataErrorAction | FetchDadataSuccessAction

