import "../../../style/final.css";
const Final = () => {


    document.body.style.background = "none";

    return (
        <div className="final-frame">
            <div className="final-image">
                <img alt="EMG final page" src="/images/final.png" />
            </div>
            <div className="final-text-1">Благодарим за сотрудничество!</div>
            <div className="final-text-2">Мы уже получили ваши данные, скоро все проверим и сообщим результат. </div>
            {/*<div className="final-text-3">
                Европейская медиагруппа © 2022-2024 | <a href="https://emg.fm">emg.fm</a> | Техподдержка <a
                href="https://support@emg.fm">support@emg.fm</a>
            </div>*/}
        </div>
    )
}

export default Final