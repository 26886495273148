import { Button } from "../../../../../Shared";

interface FormLineType {
    setLegalStatus: any,
    setFormStatus: any,
    handlerClear: any,
    setResidentStatus?:any,
}

const Status: React.FC<FormLineType> = ({
    setLegalStatus,
    setFormStatus,
    handlerClear,
    setResidentStatus
}) => {

  return (

    <div className="button-group">

      <Button text={"Юридическое лицо (ООО, ЗАО, ОАО)"} onClick={() => (setLegalStatus(1), setFormStatus(1), handlerClear(true))} disabled={false} />

      <Button text={"Индивидуальный предприниматель"} onClick={() => (setLegalStatus(2), setFormStatus(1), handlerClear(true))} disabled={false} />

      <Button text={"Физическое лицо или самозанятый"} onClick={() => (setLegalStatus(3), setFormStatus(2), setResidentStatus(true), handlerClear(true))} disabled={false} />

    </div>
  );
};

export default Status;
