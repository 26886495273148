import axios, { AxiosRequestConfig } from "axios";
import urlAPI from './api-url.json'

const baseUrl = `${(process.env.NODE_ENV === "production" || process.env.REACT_APP_ENVIRONMENT === "production") ? urlAPI.EDM.apiProdUrl : urlAPI.EDM.apiDevUrl}`;

/**
 * Вспомогательная функция для создания запроса с использованием библиотеки Axios и общей конфигурации.
 *
 * @param config Объект AxiosRequestConfig, содержащий настройки для выполнения запроса.
 * @returns Данные, полученные в результате выполнения запроса с помощью Axios.
 */
export const createAxiosRequest = async (config: AxiosRequestConfig) => {
    try {
        const response = await axios({
            ...config,
            baseURL: baseUrl
        });
        return response.data;
    } catch (error) {
        console.error('Во время API запроса произошла ошибка:', error);
        throw error; // Переброс ошибки может быть частью вашей стратегии обработки ошибок
    }
};