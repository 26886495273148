import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../store/hooks/useTypedSelector";
import { DadataBankActionTypes } from '../../../../store/types/dadataBank';
import InputField from '../../../tools/Input/InputField';
import { useInput } from "../../Form/hooks/useInput";
import FieldInputAuto from './component/FieldInputAuto';
import InputSelect from './component/InputSelect';
import { IBankProps, IModal } from '../../../../types';

interface IModalBankPropsProps {
    modal: IModal;
    setModal: (value: IModal) => void;
    bankProps: IBankProps[];
    setBankProps: (bankProps: IBankProps[]) => void;
}

const ModalBankProps: React.FC<IModalBankPropsProps> = ({
    modal,
    setModal,
    bankProps,
    setBankProps
}) => {

    const { dadataBank, error, loading } = useTypedSelector((state) => state.dadataBankRequest);
    const dispatch = useDispatch();

    const [useAutoInput, setAutoInput] = useState(true);
    const [useCorrAccount, setCorrAccount] = useState(false);

    const [useID, setID] = useState(0);

    const [bankPropId, setBankPropId] = useState(0);
    const account_number = useInput("", { isEmpty: true });
    const bank_name = useInput("", { isEmpty: true });
    const bank_city = useInput("");
    const bik = useInput("");
    const correspondent_account = useInput("");
    const comment = useInput("");

    const newBankProp: IBankProps = {
        id: bankPropId,
        bankName: bank_name.value,
        bankCity: bank_city.value,
        bik: bik.value,
        accountNumber: account_number.value,
        correspondentAccount: correspondent_account.value,
        comment: comment.value
    };

    useEffect(() => {
        if (bank_name.value.length > 1) {
            let vBank = bank_name.value;
            dispatch({ type: DadataBankActionTypes.FETCH_DADATA_BANK, vBank });
            if (vBank !== dadataBank?.request?.[useID]?.value) {
                bank_city.clear();
                bik.clear();
                correspondent_account.clear();
            }
        }
        else {
            bank_city.clear();
            bik.clear();
            correspondent_account.clear();
        }
    }, [bank_name.value])

    useEffect(() => {
        if (bank_name.value && bik.value && !correspondent_account.value)
            setCorrAccount(true);
        else
            setCorrAccount(false);
    }, [bank_name.value, bik.value, correspondent_account.value])

    const buttonSend = () => {
        const newBankProps = [...bankProps, newBankProp];

        if (modal.modalEdit === true) newBankProps.splice(modal.modalID, 1);

        setBankProps(newBankProps);

        account_number.clear();
        bank_name.clear();
        bank_city.clear();
        bik.clear();
        correspondent_account.clear();
        comment.clear();

        setModal({ ...modal, modalBankPropsResume: false, modalEdit: false, modalID: 0 })
    }

    const buttonCancel = () => {
        bank_name.clear();
        bank_city.clear()
        bik.clear();
        account_number.clear();
        correspondent_account.clear();
        comment.clear();

        setModal({ ...modal, modalBankPropsSend: false, modalBankPropsResume: false })
    }

    useEffect(() => {
        if (account_number.inputValid && bank_name.inputValid) { setModal({ ...modal, modalValid: false }) }
        else { setModal({ ...modal, modalValid: true }) }
    }, [account_number.inputValid, bank_name.inputValid])

    useEffect(() => {
        if (modal.modalEdit === true && modal.modalBankPropsResume === true) {
            const newBankProps = [...bankProps];
            const bankProp = newBankProps[modal.modalID]

            setBankPropId(bankProp.id);
            account_number.set(bankProp.accountNumber);
            bank_name.set(bankProp.bankName);
            bank_city.set(bankProp.bankCity);
            bik.set(bankProp.bik);
            correspondent_account.set(bankProp.correspondentAccount);
            comment.set(bankProp.comment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal.modalEdit === true && modal.modalBankPropsResume === true])

    return (
        <div className="input-group">

            <InputField name={"account_number_bankProps"}
                useValue={account_number}
                label={"Номер счета"} />

            <InputSelect name={"bank_name_bankProps"}
                useValue={bank_name} dadataBank={dadataBank}
                label={"Банк"}
                placeholder="БИК банка или название"
                bank_city={bank_city} bik={bik} correspondent_account={correspondent_account}
                setAutoInput={setAutoInput} setCorrAccount={setCorrAccount} setID={setID} />

            <FieldInputAuto name={"bank_city_bankProps"}
                useValue={bank_city}
                label={"Город банка"} disabled={true} loading={loading} />

            {useAutoInput && <FieldInputAuto name={"bik_bankProps"}
                useValue={bik}
                label={"БИК"} disabled={true} loading={loading} />}

            {!useAutoInput && <InputField name={"bik_bankProps"}
                useValue={bik}
                label={"БИК"} />}

            {(useAutoInput && !useCorrAccount) && <FieldInputAuto name={"correspondent_account_bankProps"}
                useValue={correspondent_account}
                label={"Коррсчет"} disabled={true} loading={loading} />}

            {(!useAutoInput || useCorrAccount) && <InputField name={"correspondent_account_bankProps"}
                useValue={correspondent_account}
                label={"Коррсчет"} />}

            <InputField name={"comment_bankProps"}
                useValue={comment}
                label={"Комментарий"} />

            <div className="modal-button-group">

                <button className="modal-button-send"
                    onClick={() => buttonSend()} disabled={modal.modalValid}>
                    {!modal.modalEdit && <div className={modal.modalValid ? "modal-button-text-disabled" : "modal-button-text"}>Добавить</div>}
                    {modal.modalEdit && <div className={modal.modalValid ? "modal-button-text-disabled" : "modal-button-text"}>Сохранить</div>}
                </button>
                <button className="modal-button-cancel" onClick={() => buttonCancel()}>
                    <div className="modal-button-text">Отмена</div>
                </button>

            </div>
        </div >
    );
};

export default ModalBankProps;
