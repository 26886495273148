import { IContragentDataModel } from "../../models";
import { createAxiosRequest } from "../api";

export const API_postNewCo = async (ContragentJson: IContragentDataModel, newCoHash: string) => {
    return createAxiosRequest({
        url: `Contragent/${newCoHash}`,
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        data: JSON.stringify(ContragentJson)
    });
};