import { FC } from "react";
import "./footer.css";

interface IFooterProps {
    companyName: string;
    startYear: number;
    websiteUrl: string;
    supportMail: string;
    supportText: string;
}

const Footer: FC<IFooterProps> = ({
    companyName,
    startYear,
    websiteUrl,
    supportMail,
    supportText
}) => {

    const currentYear = new Date().getFullYear();

    return (
        <div className="footer">
            {companyName} © {startYear}-{currentYear} | <a href={`https://${websiteUrl}`}>{websiteUrl}</a> | Техподдержка&nbsp;<a
                href={`mailto:${supportMail}?subject=${supportText}`}>{supportMail}</a>
        </div>
    );
}

export default Footer;