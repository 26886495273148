import { IContact } from "../../../../../../../types";
import ResultContacts from "./component/ResultContacts";

interface IResultContactsGroupProps {
    contacts: IContact[];
}

const ResultContactsGroup: React.FC<IResultContactsGroupProps> = ({
    contacts
}) => {
    return (
        <div>
            {contacts.map((contact: IContact, key: any) => (
                contact.fullName && <ResultContacts key={key} contact={contact}/>
            ))}
        </div>
    );
};

export default ResultContactsGroup;
