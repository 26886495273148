import "../../../style/input.css";
import { FieldDefinitionType } from "../../../types";

interface IInputFieldProps {
    useValue: FieldDefinitionType,
    label?: string,
    name?: string,
    placeholder?: string,
    disabled?: boolean,
    useTrek? : any
}

const InputField: React.FC<IInputFieldProps> = ({ 
    useValue, label, name, placeholder, disabled, useTrek 
}) => {

    return (
        <div className={useValue.isValid}>
            <div className="input-frame">
                <div className="input-title">
                    <label className="input-title-text" htmlFor={name}>
                        {label}
                        {!useValue.disableValid && useValue.isRequired && <label htmlFor={name} className={"input-title-star"}> *</label>}
                    </label>
                    {/* {!useValue.disableValid && useValue.isRequired && <label htmlFor={name} style={{ color: 'red' }}> *</label>} */}
                    {((!useValue.inputValid && useValue.isDirty)) && <div className={"input-valid-text"} style={{ color: useValue.colorError }}>{useValue.error}{useTrek}</div>}
                </div>

                <div>
                    <input name={name}
                        id={name}
                        className="input"
                        type="text"
                        placeholder={placeholder}
                        {...useValue.bind}
                        disabled={disabled}
                        autoComplete="on"
                    />
                    {useValue.showComment && <div className="input-comment">Комментарий ЕМГ: <span className="input-comment-text">{useValue.showComment}</span></div>}
                </div>
            </div>

        </div>
    );
};

export default InputField;
