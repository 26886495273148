import useSVG from "../../../../../Data/useSVG";


interface IPreFileProps {
    text: string;
    file: any;

}

const PreFile: React.FC<IPreFileProps> = ({
    text,
    file

}) => {

    const { iconDocPrew } = useSVG();
    
    return (
        <div className="prew">
        {/* <img src={file.showFile}></img> */}
            <div className="prew-header">
                <div className="prew-check-circle">
                    {iconDocPrew}
                </div>
                <div className="prew-header-text">
                    {text}
                    <div className="prew-name" onClick={file.view}>{file.value}</div>
                </div>
            </div>
            <div className="prew-delete" onClick={file.clear}>Удалить документ</div>
            <div className="prew-line" />
        </div >
    );
};

export default PreFile;
