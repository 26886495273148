import { FieldDefinitionType, IFullName, IInn } from "../../../../../types";
import useSVG from "../../../../Data/useSVG";
import Frame from "../../../../tools/Frame/Frame";
import Individual from "./component/Individual";

interface IIndividualFormProps {
    inn: IInn;
    address: FieldDefinitionType;
    address2: FieldDefinitionType;
    edo: FieldDefinitionType;
    loading: boolean;
    passport: FieldDefinitionType;
    ogrn: FieldDefinitionType;
    phone: FieldDefinitionType;
    email: FieldDefinitionType;
    fullName: IFullName;
    showLegalStatus: number;
    useTrek: any;
    showResidentStatus: boolean;
}

const IndividualForm: React.FC<IIndividualFormProps> = ({
    inn,
    address,
    address2,
    edo,
    loading,
    passport,
    ogrn,
    phone,
    email,
    fullName,
    showLegalStatus,
    useTrek,
    showResidentStatus
}) => {

    const { iconRec } = useSVG();

    return (
        <Frame
            icon={iconRec}

            header={"Реквизиты"}

            body={
                <Individual
                    inn={inn}
                    address={address}
                    address2={address2}
                    edo={edo}
                    loading={loading}
                    passport={passport}
                    ogrn={ogrn}
                    phone={phone}
                    email={email}
                    fullName={fullName}
                    showLegalStatus={showLegalStatus}
                    useTrek={useTrek}
                    showResidentStatus={showResidentStatus}
                />
            }

        />
    );
};

export default IndividualForm;
