
import { FileUpload } from "../../../../../../features/FileUpload";
import "../../../../../../style/button.css";
import { IFiles } from "../../../../../../types";

interface IDocumentEntityProps {
    files: IFiles;
}

const DocumentEntity: React.FC<IDocumentEntityProps> = ({
    files
}) => {

    const fileInputsData = [
        { file: files.genManager, text: 'Добавить протокол или решение подтверждающие полномочия' },
        { file: files.inn, text: 'Добавить свидетельство ИНН' },
        { file: files.reportReceipt, text: 'Добавить квитанцию о приеме налоговой отчетности' },
        { file: files.proxySigner, text: 'Добавить доверенность на подписанта' },
        { file: files.enterpriseCard, text: 'Добавить карточку компании' },
        { file: files.usn, text: 'Добавить уведомление о возможности применения УСН' },
        { file: files.reportInsurance, text: 'Добавить титульный лист отчета по страховым взносам' }
    ];

    return (
        <div className="button-group">
            {fileInputsData.map(({ file, text }, index) => (
                !file.value && (
                    <FileUpload key={index} useFile={file} text={text} />
                )
            ))}
        </div>
    );
};

export default DocumentEntity;
