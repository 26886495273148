import { IBankProps } from "../../../../../../../../types";
import useSVG from "../../../../../../../Data/useSVG";

interface FormLineType {
    checkingАccount: IBankProps;
}

const ResultBankProps: React.FC<FormLineType> = ({
    checkingАccount,
}) => {

    const { iconBankPrew } = useSVG();

    return (
        <div className="prew-result">
            <div className="result-line" />
            <div className="prew prew-group">
                <div className="prew-header">
                    {iconBankPrew}
                    <div className="prew-header-text">
                        {checkingАccount.bankName}
                        <div className="prew-info">
                        {checkingАccount.bankCity && <div>{checkingАccount.bankCity}</div>}
                            {checkingАccount.bik && <div>БИК {checkingАccount.bik}</div>}
                            {checkingАccount.accountNumber && <div>Р/С {checkingАccount.accountNumber}</div>}
                            {checkingАccount.correspondentAccount && <div>К/С {checkingАccount.correspondentAccount}</div>}
                            {checkingАccount.comment}<div />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultBankProps;
