import "../../../style/final.css";
const notAvailable = () => {


    document.body.style.background = "none";

    return (
        <div className="final-frame">
            <div className="final-image">
                <img alt="EMG final page" src="/images/notAvailable.png" />
            </div>
            <div className="final-text-1">Страница недоступна.</div>
            <div className="final-text-2">Действие ссылки истекло. Для получения новой обратитесь к сотруднику ЕМГ. </div>
            <div className="final-text-3">
                Европейская медиагруппа © <b>2022-2024</b> | <a href="https://emg.fm">emg.fm</a> | Техподдержка <a
                href="https://support@emg.fm">support@emg.fm</a>
            </div>
        </div>
    )
}

export default notAvailable