import useSVG from "../../../Data/useSVG";
import Frame from "../../../tools/Frame/Frame";
import PartnerType from "./PartnerType/PartnerType";
import Resident from "./Resident/Resident";
import Status from "./Status/Status";

interface ISelectContragentProps {
    showForm: number;
    setFormStatus: any;
    setLegalStatus: (legalStatus: number) => void;
    setResidentStatus: (residentStatus: boolean) => void;
    setClientStatus: (clientStatus: boolean) => void;
    setProviderStatus: (providerStatus: boolean) => void;
    useClient: boolean;
    useProvider: boolean;
    handlerClear: any;
}

const SelectContragent: React.FC<ISelectContragentProps> = ({
    showForm,
    setFormStatus,
    setLegalStatus,
    setResidentStatus,
    setClientStatus,
    useClient,
    setProviderStatus,
    useProvider,
    handlerClear
}) => {


    const { iconNext } = useSVG();

    return (
        <Frame
            icon={iconNext}

            header={
                (showForm === 0 && "Юридический статус партнера") ||
                (showForm === 1 && "Резиденство партнера") ||
                (showForm === 2 && "Тип партнера")
            }

            body={
                (showForm === 0 && <Status setLegalStatus={setLegalStatus} setFormStatus={setFormStatus} setResidentStatus={setResidentStatus} handlerClear={handlerClear} />) ||
                (showForm === 1 && <Resident setResidentStatus={setResidentStatus} setFormStatus={setFormStatus} />) ||
                (showForm === 2 && <PartnerType setFormStatus={setFormStatus} setClientStatus={setClientStatus} useClient={useClient}
                    setProviderStatus={setProviderStatus} useProvider={useProvider} />)
            }
        />
    );
};

export default SelectContragent;
