import { ContragentState, ContragentAction, ContragentActionTypes } from "../types/contragentTypes";

const initialState: ContragentState = {
    loadingCo: false,
    errorCo: null,
    contragent: [],
}

export const contragentReducer = (state = initialState, action: ContragentAction): ContragentState => {
    switch (action.type) {
        case ContragentActionTypes.FETCH_CONTRAGENT:
            return {
                loadingCo: true,
                errorCo: null,
                contragent: []
            }
        case ContragentActionTypes.FETCH_CONTRAGENT_SUCCESS:
            return {
                loadingCo: false,
                errorCo: null,
                contragent: action.payload
            }
        case ContragentActionTypes.FETCH_CONTRAGENT_ERROR:
            return {
                loadingCo: false,
                errorCo: action.payload,
                contragent: []
            }

        default:
            return state
    }

}