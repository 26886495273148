import React from "react";
import useToJSONContragent from "../../../../Data/useToJSONContragent";
import ResultDetails from "./Preview/ResultDetails/ResultDetails";
import ResultBankPropsGroup from "./Preview/ResultBankProps/ResultBankPropsGroup";
import ResultContactsGroup from "./Preview/ResultContacts/ResultContactsGroup";
import ResultFilesGroup from "./Preview/ResultFiles/ResultFilesGroup";
import { IBankProps, IContact, IFields, IFiles } from "../../../../../types";
import { IContragentDataModel } from "../../../../../models";

interface IResultProps {
    setFormStatus: any;
    showLegalStatus: number;
    showResidentStatus: boolean;
    files: IFiles;
    fields: IFields;
    contacts: IContact[];
    bankProps: IBankProps[];
    useClient: any;
    useProvider: any;
    showNewCoHash: string;
    headContactFullname?: string;
    onSendJSON: (ContragentJson: IContragentDataModel) => void;
}

const Result: React.FC<IResultProps> = ({
    showLegalStatus,
    showResidentStatus,
    files,
    fields,
    contacts,
    bankProps,
    useClient,
    useProvider,
    headContactFullname,
    onSendJSON
}) => {
    const ContragentJson = useToJSONContragent(files, fields, showLegalStatus, showResidentStatus, useClient, useProvider, contacts, bankProps, headContactFullname);

    const handlerResultButton = () => {
        onSendJSON(ContragentJson);
    };

    return (
        <div>
            <ResultDetails fields={fields} showLegalStatus={showLegalStatus} />

            {bankProps[0] && <ResultBankPropsGroup bankProps={bankProps} />}

            {contacts[0] && <ResultContactsGroup contacts={contacts} />}

            <ResultFilesGroup files={files} />

            <div className="button-group">
                <button className='button-result' onClick={handlerResultButton}>
                    <div className="button-result-text">Отправить в ЕМГ</div>
                </button>
            </div>
        </div >
    );
};

export default Result;
