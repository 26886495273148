import { useState } from 'react';
import { useValidation } from './useValidation';
import { API_addFile, API_deleteFile } from '../../../../API/files/post';
import { API_getFile } from '../../../../API/files/get';
import { IFileInputState, IValidations, TSetHttpError } from '../../../../types';
import { ACCEPT_FORMAT_FILE } from '../../../../Shared/config';


export function useFileInput(initialValue: any, validations: IValidations, id: string, newCoHash: string, setHttpError: TSetHttpError): IFileInputState {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const [showFileInfo, setFileInfo] = useState('');
    const [showComment, setComment] = useState('');
    const valid = useValidation(value, validations, undefined, undefined, showFileInfo)

    const accept = ACCEPT_FORMAT_FILE;
    const type = "file";
    const colorButton = (!valid.inputValid && isDirty) ? valid.fileError : (value) ? "success" : "primary";

    const uploadFile = async (e: any) => {
        const formData = new FormData();
        formData.append("formFile", e);
        formData.append("fileName", id + "_" + e.name);
        const result = await API_addFile(newCoHash, formData);

        if (result === 200) {
            setValue(e.name);
            setFileInfo(e)
        }
        if (result === 408) setHttpError(408);
    }

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files[0]) {
            await uploadFile(event.target.files[0]);
        }
    }

    const clear = async (shareFile: boolean = true) => {
        if (shareFile) {
            const statusAPI = await API_deleteFile(id, value, newCoHash)
            if (statusAPI === 408) setHttpError(408);
        };
        setValue('');
        setDirty(false);
        setFileInfo('');
    };

    const set = async (e: string | undefined) => {
        if (e !== undefined) {
            const newValue = e.includes(id + '_') ? e.replace(id + '_', '') : e;
            setValue(newValue);
        }
    };

    const view = async () => {
        const response = await API_getFile(id, value, newCoHash);
        const url = window.URL.createObjectURL(new Blob([response], { type: response.type }));
        window.open(url);
        window.URL.revokeObjectURL(url);
    }

    const onBlur = (e: boolean = true) => {
        setDirty(e)
    };

    const isValid = showComment ? "input-valid valid-blue" : 'input-valid';

    return {
        bind: { onChange, onBlur, accept, type },
        showFileInfo,
        uploadFile,
        colorButton,
        value,
        id,
        clear,
        set,
        view,
        isDirty,
        onBlur,
        isValid,
        ...valid
    }
}