import { FileUpload } from "../../../../../../features/FileUpload";
import { IFiles } from "../../../../../../types";

interface IDocumentSelfProps {
    files: IFiles;
}

const DocumentSelf: React.FC<IDocumentSelfProps> = ({
    files
}) => {

    const fileInputsData = [
        { file: files.passport, text: 'Добавить паспорт' },
        { file: files.snils, text: 'Добавить СНИЛС' },
        { file: files.regCertificate, text: 'Добавить справку о постановке на учет' },
        { file: files.props, text: 'Добавить реквизиты' }
    ];

    return (
        <div className="button-group">
            {fileInputsData.map(({ file, text }, index) => (
                !file.value && (
                    <FileUpload key={index} useFile={file} text={text} />
                )
            ))}
        </div>
    );
};

export default DocumentSelf;
