import { useDispatch, useSelector } from "react-redux";
import { DadataActionTypes } from "../../../../../../store/types/dadata";
import "../../../../../../style/input.css";
import InputField from "../../../../../tools/Input/InputField";
import TextAreaField from "../../../../../tools/Input/TextAreaField";
import { Button } from "../../../../../../Shared";
import { FieldDefinitionType, ICompany, IInn } from "../../../../../../types";
import { useTypedSelector } from "../../../../../../store/hooks/useTypedSelector";
import { useEffect, useRef, useState } from "react";

interface IEntityProps {
    loading: boolean;
    inn: IInn;
    showResidentStatus: boolean;
    company: ICompany;
    useAddress: boolean;
    setAddressStatus: any;
    edo: FieldDefinitionType;
    useTrek: any;
    setBranch: (branch: string) => void;
}

const Entity: React.FC<IEntityProps> = ({
    loading,
    inn,
    showResidentStatus,
    company,
    useAddress,
    setAddressStatus,
    edo,
    useTrek,
    setBranch
}) => {
    const dispatch = useDispatch();
    const { dadata } = useTypedSelector((state) => state.request);
    const [showList, setShowList] = useState(0)

    const refListBox = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (refListBox.current && !refListBox.current.contains(event.target)) {
                setShowList(0);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const onClickINN = () => {
        const vInn = inn.value;
        dispatch({ type: DadataActionTypes.FETCH_DADATA, vInn });
        setShowList(1)
    };

    const onClickFilial = (filial: any) => {
        setShowList(0)
        setBranch(filial.kpp)
    }

    return (
        <div className="input-group">
            <InputField name={"inn"}
                useValue={inn}
                label={"ИНН"}
                placeholder={showResidentStatus === true ? "Введите 10-ти значный ИНН" : "Введите ИНН"}
                useTrek={useTrek}
                disabled={inn.isDisabled}
            />
            <div className="select is-active">
                <div className="button-input">
                    <Button
                        text={(loading ? "Заполнение реквизитов..." : "Заполнить реквизиты автоматически по ИНН")}
                        onClick={onClickINN}
                        disabled={(loading || inn.isEntity || inn.isResident || inn.isEmpty || inn.isNotLengthINN) ? true : false}
                    />
                </div>

                {showList === 1 && dadata.length > 1 && <div ref={refListBox} className="select__body">
                    {dadata.map((filial: any, key: any) =>
                        <div className="select__item" key={key} onClick={() => onClickFilial(filial)}>
                            <div className="select_item-text">{filial.companyName}</div>
                            <div className="select_item-text_bic">КПП {filial.kpp}</div>
                        </div>
                    )}
                </div>}
            </div>
            <TextAreaField name={"companyName"}
                useValue={company.companyName}
                label={"Полное название"}
                placeholder='Например, Общество с ограниченной ответственностью "Ромашка"'
                loading={loading}
            />

            <InputField name={"companyShortName"}
                useValue={company.companyShortName}
                label={"Краткое название"}
                placeholder='Например, ООО "Ромашка"'
            />

            {showResidentStatus && <InputField name={"kpp"}
                useValue={company.kpp}
                label={"КПП"}
                placeholder='Например, 12540005565'
            />}

            {showResidentStatus && <InputField name={"ogrn"}
                useValue={company.ogrn}
                label={"ОГРН"}
                placeholder='Например, 1254500032'
            />}

            <TextAreaField name={"address"}
                useValue={company.address}
                label={"Юридический адрес"}
                placeholder='Например, 110350, г. Москва, ул. Кожевникова 25, оф. 4'
                loading={loading}
            />

            <TextAreaField name={"address2"}
                useValue={company.address2}
                useValue2={company.address}
                label={"Фактический адрес"}
                placeholder='Например, 110350, г. Москва, ул. Кожевникова 25, оф. 4'
                textCheckBox='совпадает с юридическим'
                useCheckBox={useAddress}
                setCheckBoxStatus={setAddressStatus}
                loading={loading}
            />

            <InputField name={"edo"}
                useValue={edo}
                label={"Идентификатор участника ЭДО"}
                placeholder='Например, 99912222020203443-КЕУ'
            />

        </div>
    );
};

export default Entity;
