import { useInput } from "../../Form/hooks/useInput";
import { useEffect, useState } from "react";
import InputField from "../../../tools/Input/InputField";
import { IContact, IModal } from "../../../../types";
import CheckBoxSquareSmall from "../../../tools/CheckBox/CheckBoxSquareSmall";

interface IModalContactsProps {
    modal: IModal;
    setModal: (value: IModal) => void;
    contacts: IContact[];
    setContacts: (value: IContact[]) => void;
    headContactFullname?: string;
    setHeadContactFullname: (value: string) => void;
}

const ModalContacts: React.FC<IModalContactsProps> = ({
    modal,
    setModal,
    contacts,
    setContacts,
    headContactFullname,
    setHeadContactFullname
}) => {
    const [contactId, setContactId] = useState(0);

    const [isHeadContact, setHeadContact] = useState<boolean>(false);

    const position = useInput("", { isEmpty: true });
    const firstname = useInput("", { isEmpty: true });
    const lastname = useInput("", { isEmpty: true });
    const patronymic = useInput("");
    const phone = useInput("", { isPhone: true });
    const email = useInput("", { isEmail: true });
    const comment = useInput("");

    const fullName = lastname.value + " " + firstname.value + " " + patronymic.value;

    const newContact: IContact = {
        id: contactId,
        position: position.value,
        fullName: fullName,
        phoneNumber: phone.value,
        email: email.value,
        comment: comment.value
    };

    const titleContext = !isHeadContact && headContactFullname !== undefined && headContactFullname !== "" ? "Руководитель: " + headContactFullname : undefined;

    const buttonSend = () => {
        const newContacts = [...contacts, newContact];

        if (modal.modalEdit === true) newContacts.splice(modal.modalID, 1);

        setContacts(newContacts);
        if (isHeadContact) {
            setHeadContactFullname(newContact.fullName);
        } else if (headContactFullname === fullName){
            setHeadContactFullname("");
        }

        position.clear();
        firstname.clear();
        lastname.clear();
        patronymic.clear();
        phone.clear();
        email.clear();
        comment.clear();

        setModal({ ...modal, modalContactsSend: false, modalContactsResume: false, modalEdit: false, modalID: 0 })
    }


    const buttonCancel = () => {
        position.clear();
        firstname.clear();
        lastname.clear();
        patronymic.clear();
        phone.clear();
        email.clear();
        comment.clear();

        setModal({ ...modal, modalContactsSend: false, modalContactsResume: false, modalEdit: false, modalID: 0 })
    }

    useEffect(() => {
        if (position.inputValid && firstname.inputValid && lastname.inputValid) { setModal({ ...modal, modalValid: false }) }
        else { setModal({ ...modal, modalValid: true }) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position.inputValid, firstname.inputValid, lastname.inputValid])

    useEffect(() => {
        if (modal.modalEdit === true && modal.modalContactsResume === true) {
            const editContacts = [...contacts];
            const editContact = editContacts[modal.modalID]

            var names = editContact.fullName.split(' ');
            setContactId(editContact.id)
            position.set(editContact.position);
            firstname.set(names[1]);
            lastname.set(names[0]);
            patronymic.set(names[2]);
            phone.set(editContact.phoneNumber);
            email.set(editContact.email);
            comment.set(editContact.comment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal.modalEdit === true && modal.modalContactsResume === true])

    useEffect(() => {
        if (headContactFullname === fullName)
            setHeadContact(true);
    }, [fullName, headContactFullname])

    return (
        <div>
            <div className="input-group">
                <InputField name={"position_contact"}
                    useValue={position}
                    label={"Должность"}
                    placeholder="Например, Генеральный директор"
                />

                <div className="input-valid">
                    <div className="input-title-text">
                        <CheckBoxSquareSmall
                            setCheck={setHeadContact}
                            useCheck={isHeadContact}
                            text="Является руководителем"
                            titleContext={titleContext}
                        />
                    </div>
                </div>

                <InputField name={"lastname_contact"}
                    useValue={lastname}
                    label={"Фамилия"}
                    placeholder="Например, Иванов"
                />

                <InputField name={"firstname_contact"}
                    useValue={firstname}
                    label={"Имя"}
                    placeholder="Например, Иван"
                />

                <InputField name={"patronymic_contact"}
                    useValue={patronymic}
                    label={"Отчество"}
                    placeholder="Например, Иванович"
                />

                <InputField name={"phone_contact"}
                    useValue={phone}
                    label={"Телефон"}
                    placeholder="Например, +7 (999) 999-99-99"
                />

                <InputField name={"email_contact"}
                    useValue={email}
                    label={"Электронная почта"}
                    placeholder="Например, 2lKwz@example.com"
                />

                <InputField name={"comment_contact"}
                    useValue={comment}
                    label={"Комментарий"}
                />

                <div className="modal-button-group">

                    <button className="modal-button-send"
                        onClick={() => buttonSend()} disabled={modal.modalValid}>
                        {!modal.modalEdit && <div className={modal.modalValid ? "modal-button-text-disabled" : "modal-button-text"}>Добавить</div>}
                        {modal.modalEdit && <div className={modal.modalValid ? "modal-button-text-disabled" : "modal-button-text"}>Сохранить</div>}
                    </button>
                    <button className="modal-button-cancel" onClick={() => buttonCancel()}>
                        <div className="modal-button-text">Отмена</div>
                    </button>

                </div>

            </div>
        </div>
    );
};

export default ModalContacts;
