import React from "react";
import useSVG from "../../../Data/useSVG";
import Frame from "../../../tools/Frame/Frame";
import Result from "./component/Result";
import { IBankProps, IContact, IFields, IFiles } from "../../../../types";
import { IContragentDataModel } from "../../../../models";

interface IResultFormProps {
    setFormStatus: any;
    showLegalStatus: number;
    showResidentStatus: boolean;
    files: IFiles;
    fields: IFields;
    contacts: IContact[];
    bankProps: IBankProps[];
    useClient: boolean;
    useProvider: boolean;
    showNewCoHash: string;
    LegalStatusPoptext?: string;
    ResidentStatusPoptext?: string;
    ClientPoptext?: string;
    ProviderPoptext?: string;
    headContactFullname?: string;
    onSendJSON: (ContragentJson: IContragentDataModel) => void;
}

const ResultForm: React.FC<IResultFormProps> = ({
    setFormStatus,
    showLegalStatus,
    showResidentStatus,
    files,
    fields,
    contacts,
    bankProps,
    useClient,
    useProvider,
    LegalStatusPoptext,
    ResidentStatusPoptext,
    ClientPoptext,
    ProviderPoptext,
    showNewCoHash,
    headContactFullname,
    onSendJSON
}) => {

    const { iconResult } = useSVG();

    switch (showLegalStatus) {
        case 1:
            LegalStatusPoptext = "Юридическое лицо"
            break;
        case 2:
            LegalStatusPoptext = "Индивидуальный предприниматель"
            break;
        case 3:
            LegalStatusPoptext = "Физическое лицо или самозанятый"
            break;
        default:
            break;
    }

    switch (showResidentStatus) {
        case true:
            ResidentStatusPoptext = " / Резидент "
            break;
        case false:
            ResidentStatusPoptext = " / Нерезидент "
            break;
        default:
            break;
    }

    if (useClient && !useProvider) { ClientPoptext = " / Клиент" }

    if (useProvider && !useClient) { ProviderPoptext = " / Поставщик" }

    if (useClient && useProvider) { ClientPoptext = " / Клиент, Поставщик" }

    return (
        <Frame
            icon={iconResult}

            header={"Новый партнер"}

            poptext={<div className="frame-result-poptext-text">{LegalStatusPoptext}{ResidentStatusPoptext}{ClientPoptext}{ProviderPoptext}</div>}

            body={
                <Result
                    setFormStatus={setFormStatus} showNewCoHash={showNewCoHash}
                    showLegalStatus={showLegalStatus} showResidentStatus={showResidentStatus}
                    files={files} fields={fields}
                    contacts={contacts} bankProps={bankProps}
                    useClient={useClient} useProvider={useProvider}
                    headContactFullname={headContactFullname}
                    onSendJSON={onSendJSON}
                />
            }
        />
    );
};

export default ResultForm;
