import { FileUpload } from "../../../../../../features/FileUpload";
import { IFiles } from "../../../../../../types";

interface IDocumentNonResidentProps {
    files: IFiles;
}

const DocumentNonResident: React.FC<IDocumentNonResidentProps> = ({
    files
}) => {

    const fileInputsData = [
        { file: files.regCertificate, text: 'Добавить свидетельство о регистрации' },
        { file: files.taxNonResidentStatus, text: 'Добавить документ, подтверждающий статус налогового резидента в стране регистрации' },
        { file: files.proxySigner, text: 'Добавить документ, подтверждающий полномочия подписанта' },
        { file: files.enterpriseCard, text: 'Добавить полную карточку предприятия с реквизитами банка' }
    ];

    return (
        <div className="button-group">
            {fileInputsData.map(({ file, text }, index) => (
                !file.value && (
                    <FileUpload key={index} useFile={file} text={text} />
                )
            ))}
        </div>
    );
};

export default DocumentNonResident;
